import cn from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { appInit } from '../../../config'
import { changeIBScreen } from '../../../ib/redux/state'
import { useIBState, useShouldShowIBportal } from '../../../ib/utils/hooks'
import { useRedirect as ibRedirect } from '../../../ib/utils/useRedirect'
import { getIBScreenType } from '../../../ib/utils/utils'
import { logout } from '../../../redux/actions'
import { AppState } from '../../../redux/appState'
import { changeScreen, setPortalAccountDomain, setUserCountry } from "../../../redux/slices/app"
import { hideSidebar } from '../../../redux/slices/dashboard'
import { Store } from '../../../redux/store'
import api from '../../../utils/api'
import { BREAKPOINTS, ScreenSize, WtrPageView, __STORAGE } from "../../../utils/enums"
import { use2FACheck, useAccounts, useAccountVerifiedEvent, useRedirectToWTRhandler, useUserApplications } from '../../../utils/hooks'
import appRoutes, { accountPath, dashboardPath, externalRoutes, ibPath, profilePath } from "../../../utils/routes"
import { ApplicationStatus } from '../../../utils/types'
import { useEmailRedirect } from '../../../utils/useEmailRedirect'
import { usePhoneRedirect } from '../../../utils/usePhoneRedirect'
import { useRedirect as individualRedirect } from '../../../utils/useRedirect'
import Utils from '../../../utils/utils'
import DemoExpirationWarning from '../../DemoExpirationWarning'
import { hideGlobalLoader } from '../../GlobalLoader'
import SubscriptionTerminatedError from '../../SubscriptionTerminatedError'
import TermsAndConditions from '../../TermsAndConditions'
import { needShowVPSModal } from '../../VPSModal/index'
import Account from '../Account'
import { useRedirect as corporateRedirect } from '../Account/Corporate/useRedirect'
import AppErrorBoundary from './AppErrorBoundary'
import styles from './style.module.scss'

const Error = React.lazy(() => import('../../Error'))
const Migration = React.lazy(() => import('../../Presentational/Migration'))
const Dashboard = React.lazy(() => import('../Dashboard'))
const IB = React.lazy(() => import('../../../ib'))
const Profile = React.lazy(() => import('../Profile'))
const VPSModal = React.lazy(() => import('../../VPSModal/index'))

Modal.setAppElement('body')

interface AppProps extends RouteComponentProps {
}

const App: FC<AppProps> = ({ history, location }) => {
    const dispatch = useDispatch()
    const [ready, setready] = useState(false)
    const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>()
    const [ibLanded, setIbLanded] = useState(false)
    const shouldShowIBportal = useShouldShowIBportal()

    const store: AppState = useSelector(
        (state: Store) => state.App)

    const { redirectByStatus: individualRedirectByStatus, redirectToAccounts, wtrPostMessageListener } = individualRedirect()
    const { redirectByStatus: corporateRedirectByStatus } = corporateRedirect()
    const { redirectByStatus: ibRedirectByStatus } = ibRedirect()
    const { latestApplication } = useUserApplications()
    const validateEmail = useEmailRedirect()
    const validatePhoneNumber = usePhoneRedirect()
    const fa2Passed = use2FACheck()
    const handleRedirectToWTR = useRedirectToWTRhandler('_self')
    const { liveAccounts } = useAccounts()
    const liveTT = liveAccounts.find(x => x.account.platform.name === 'ThinkTrader')
    const hasComissionAccount = useMemo(() => liveAccounts.find(x => x.platformAccountType === 'Commission'), [liveAccounts])
    const { application: ibApplication } = useIBState()

    const screenSize = useSelector(
        (state: Store) => state.App.screen,
        shallowEqual
    )

    const { userCountry } = store

    const onResize = (width: number, height: number) => {
        const root = document.getElementById("root")
        if (!width) return
        switch (true) {
            case width < BREAKPOINTS.MOBILE:
                dispatch(changeScreen(ScreenSize.Mobile))
                dispatch(hideSidebar())
                root?.setAttribute("screen", "mobile")
                break
            case width > BREAKPOINTS.DESKTOP:
                dispatch(changeScreen(ScreenSize.Desktop))
                root?.removeAttribute("screen")
                break
            default:
                dispatch(changeScreen(ScreenSize.Tablet))
                root?.removeAttribute("screen")
                break
        }
        dispatch(changeIBScreen(getIBScreenType(width)))
    }

    const { sessionChecked,
        loggedIn,
        loggedOut,
        initError,
        appStatus,
        userEmailVerified,
        userProfile,
        twoFa,
        notificationMap,
        newTradeAccountInfo,
        redirectedFromWTR
    } = store

    /*     useEffect(() => {
            initCaptcha(language, userCountry?.code3)
        }, [initCaptcha, language, userCountry])
     */
    useEffect(() => {
        window.navigateToPage = (url: string) => history.push(url)
    }, [history])

    useEffect(() => {
        if (loggedOut) {
            setApplicationStatus(undefined)
            setIbLanded(false)
        }
    }, [loggedOut])

    useEffect(() => {
        if (store.language === 'ja' && store.countries.length) {
            const japan = store.countries.find(x => x.code3 === 'JPN')
            if (japan) {
                dispatch(setUserCountry(japan))
                dispatch(setPortalAccountDomain(japan.organization.name))
            }
        }
    }, [dispatch, store.language, store.countries])

    useEffect(() => {
        // for test purposes only
        console.log('>> userCountry', userCountry)
    }, [userCountry])

    const ssoIntoWTR = useCallback(async () => {
        const status = new URLSearchParams(window.location.search).get(WtrPageView.Status) as string
        const transactionId = new URLSearchParams(window.location.search).get('transaction_id') as string

        try {
            const response = await api.getPaymentInfo({
                transaction_id: transactionId,
            })

            handleRedirectToWTR(liveTT?.account, 'LIVE', {
                targetPage: WtrPageView.Status,
                status,
                transactionId,
                accountNumber: response.payload[0].result.account.accountNumber
            })
        }
        catch {
            console.log('failed to retrieve transaction data')
        }

        return
    }, [handleRedirectToWTR, liveTT?.account])

    useEffect(() => {
        const checkWTR = async () => {
            await appInit
            const sso = new URLSearchParams(window.location.search).get(WtrPageView.SSO) as string
            if (sso && userProfile) {
                ssoIntoWTR()
                return
            } else if (redirectedFromWTR && loggedIn) {
                Utils.postMessageToWTR({ message: 'loggedIn' })
            }
        }
        checkWTR()
    }, [ssoIntoWTR, userProfile, loggedIn, redirectedFromWTR])

    useEffect(() => {
        // Listen to post messages from ThinkTrader
        window.addEventListener('message', wtrPostMessageListener)

        return () => {
            window.removeEventListener('message', wtrPostMessageListener)
        }
    }, [wtrPostMessageListener])

    useAccountVerifiedEvent()

    const { welcome, account, register } = appRoutes

    const redirectIfNotLogged = useCallback((path) => {
        const pathnameWithoutTrailingSlash = Utils.removeTrailingSlash(path)
        return ![
            account.createAccount.live,
            account.createAccount.demo,
            account.personalInformation,
            account.login,
            welcome.demo,
            register.root,
            register.live,
            register.demo,
            register.ib,
            account.resetPasswordStep1,
            externalRoutes.scanId.success,
            externalRoutes.scanId.failure
        ].includes(pathnameWithoutTrailingSlash)
    }, [account, register, welcome])

    useEffect(() => {
        const process = async () => {
            const appReady = () => {
                hideGlobalLoader()
                setready(true)
            }
            if (sessionChecked) {
                if (store.passwordResetToken) {
                    history.push(appRoutes.account.resetPasswordStep3)
                    appReady()
                } else if (!loggedIn) {
                    setApplicationStatus(undefined)
                    setIbLanded(false)
                    if (redirectIfNotLogged(window.location.pathname)) {
                        history.push(appRoutes.account.login)
                    }
                    appReady()
                } else {
                    if (fa2Passed()) {
                        appReady()
                        if (!await validateEmail()) return
                        if (!validatePhoneNumber()) return
                        if (newTradeAccountInfo) {
                            console.log('>> redirect from App.tsx with newTradeAccountInfo and ', newTradeAccountInfo, redirectedFromWTR)
                            if (redirectedFromWTR) return
                            history.push(appRoutes.dashboard.accounts.new + history.location.search)
                        } else if (ibApplication || hasComissionAccount) {
                            if (ibLanded) return
                            setIbLanded(true)
                            ibRedirectByStatus()
                        } else if (appStatus) {
                            if (latestApplication && (applicationStatus !== appStatus)) {
                                switch (latestApplication.type) {
                                    case 'corporate':
                                        corporateRedirectByStatus()
                                        break
                                    default:
                                        individualRedirectByStatus()
                                }
                                setApplicationStatus(appStatus)
                            }
                        } else {
                            console.log('>> redirectToAccounts from App.tsx')
                            redirectToAccounts()
                        }

                    } else appReady()
                }
            }
        }
        process()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionChecked,
        loggedIn,
        initError,
        appStatus,
        latestApplication,
        newTradeAccountInfo,
        twoFa,
        userEmailVerified,
        history,
        fa2Passed,
        validateEmail,
        validatePhoneNumber,
        applicationStatus,
        ibApplication,
        hasComissionAccount,
        ibLanded
    ])

    useEffect(() => {
        const unlisten = window.addEventListener('TokenExpired', () => {
            if (loggedIn) {
                dispatch(logout())
                history.push(appRoutes.account.login)
            }
        })
        return unlisten
    }, [dispatch, history, loggedIn])

    useEffect(() => {
        const unlisten = history.listen((loc, action) => {
            if (loggedIn && notificationMap.length && !loc.pathname.includes('login')) {
                // dispatch(getNotifications())
                if (screenSize === ScreenSize.Mobile)
                    dispatch(hideSidebar())
            }
        })
        return unlisten
    }, [dispatch, history, loggedIn, notificationMap.length, screenSize])

    const click = useCallback(() => {
        Utils.writeTimestamp(__STORAGE.lastClick)
    }, [])

    if (!ready) return null

    const className = cn(
        styles.app,
        {
            'rtl': store.rtl,
            [styles.mobile]: screenSize === ScreenSize.Mobile
        }
    )

    return (<AppErrorBoundary>
        <div className={className} onClick={click}>
            <ReactResizeDetector
                handleWidth
                // handleHeight
                refreshMode="debounce"
                refreshRate={100}
                onResize={onResize} />

            <Switch>
                <Route path={accountPath} component={Account} />
                {loggedIn && [
                    <Route path={dashboardPath} key={dashboardPath} component={Dashboard} />,
                    <Route path={profilePath} key={profilePath} component={Profile} />,
                    shouldShowIBportal && <Route path={ibPath} key={ibPath} component={IB} />
                ]}
                <Redirect key='app-redirect' to={appRoutes.dashboard.accounts.root} />
            </Switch>

            {needShowVPSModal(store) && <VPSModal />}
            <Migration />
            <TermsAndConditions />
            <DemoExpirationWarning />
            <SubscriptionTerminatedError />
            <Error />
        </div>
    </AppErrorBoundary>)
}

export default App