import moment from 'moment'
import 'normalize.css'
// import 'es6-map'
import 'rc-slider/assets/index.css'
import React, { Suspense } from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import GreenIdPage from './components/Container/Account/GreenId/GreenIdPage'
import ScanIdMobilePage from './components/Container/Account/UploadDocs/GBGScanID/ScanIDMobile'
import SumSubPage from './components/Container/Account/UploadDocs/SumSub/SumSubPage'
import TrustDockPage from './components/Container/Account/UploadDocs/TrustDock/TrustDockPage'
import App from './components/Container/App'
import CheckoutFramesPage from './components/Container/Dashboard/Deposit/CheckoutComProcessor/CheckoutFramesPage'
import Loader from './components/Loader'
import PaymentResult from './components/PaymentResult'
import './css/fonts.css'
import './css/index.scss'
import './i18n/i18n'
import store from './redux/store'
import './resources/fonts/Figtree/font.css'
import './resources/fonts/Noto_Sans/font.css'
import './resources/fonts/Noto_Sans_Arabic/font.css'
import './resources/fonts/Noto_Sans_JP/font.css'
import './resources/fonts/Noto_Sans_SC/font.css'
import './resources/fonts/Noto_Sans_TC/font.css'
import './resources/fonts/Noto_Sans_Thai/font.css'
import './resources/fonts/Roboto/font.css'
import { ie11 } from './utils/constants/browser'
import { externalRoutes } from './utils/routes'
import { ThemeProvider } from './utils/themes'


// import * as serviceWorker from './serviceWorkerRegistration'
moment.locale('en')
const basename = "/"

if (ie11) document.documentElement.setAttribute("mode", "ie11")

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider>
            <Suspense fallback={<Loader />}>
                <Router basename={basename} >
                    <Switch>
                        <Route exact path='/checkoutcom' component={CheckoutFramesPage} />
                        <Route exact path='/trustdock' component={TrustDockPage} />
                        <Route exact path='/greenid' component={GreenIdPage} />
                        <Route exact path={externalRoutes.scanId.base} component={ScanIdMobilePage} />
                        <Route exact path='/sumsub' component={SumSubPage} />
                        <Route exact path={['/payment-result.html', '/payment-result']} component={PaymentResult} />
                        <Route path='/' component={App} />
                    </Switch>
                </Router>
            </Suspense>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
)


// serviceWorker.register()