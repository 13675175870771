
import { useCallback } from 'react'
import { appConfig } from '../../../../../config'

const useDepositResultPage = () => {
    // const { liveAppCompleted, isNewPortalAccount } = useSelector((state: Store) => state.App)

    const getResultPage = useCallback((tfxTransactionId?: string, ssoToWtr?: boolean) => {
        // if (isNewPortalAccount && !liveAppCompleted) {
        //     return `${window.location.origin}${routes.dashboard.approved}?transaction_id={tfxTransactionId}&status={status}`
        // } else {
        const base = `/dashboard/funds/status?transaction_id=`
        if (tfxTransactionId) {
            return `${base}${tfxTransactionId}`
        } else if (ssoToWtr) {
            // return `${window.location.origin}${base}{tfxTransactionId}&status={status}&sso=true`
            return `${appConfig.WTR_URL}`
        }
        return `${window.location.origin}${base}{tfxTransactionId}&status={status}`
        // }
    }, [])

    return getResultPage
}


export default useDepositResultPage