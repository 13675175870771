import { ComponentProps, VFC } from "react"

import iphone from '../../../resources/images/iphone opt6.gif'
import japanIphone from '../../../resources/images/japan-iphone.gif'

import mastercard from '../../../resources/images/mastercard.svg'
import visa from '../../../resources/images/visa.svg'

import facebook from '../../../resources/images/socials/facebook.png'
import gmail from '../../../resources/images/socials/gmail.png'
import line from '../../../resources/images/socials/line.svg'
import telegram from '../../../resources/images/socials/telegram.svg'
import twitter from '../../../resources/images/socials/twitter.png'
import tradingView from '../../../resources/images/trading-view.png'

import tmCondensedLogo from '../../../resources/images/think-markets-logo-stacked.svg'
import tmLogo from '../../../resources/images/Logo15Years.svg'
import tmLogoLight from '../../../resources/images/LogoWhiteScreen15Years.svg'
// import tmLogoDark from '../../../resources/images/LogoDarkScreen15Years.svg'

import dMT4 from '../../../resources/images/platforms/congrats/dMT4.png'
import dMT5 from '../../../resources/images/platforms/congrats/dMT5.png'
import mMT4 from '../../../resources/images/platforms/congrats/mMT4.png'
import mMT5 from '../../../resources/images/platforms/congrats/mMT5.png'
import dTT from '../../../resources/images/platforms/congrats/dTT.png'
import mTT from '../../../resources/images/platforms/congrats/mTT.png'

import mt4Laptop from '../../../resources/images/mt4-laptop.png'
import mt5Laptop from '../../../resources/images/mt5-laptop.png'
import pammLaptop from '../../../resources/images/pamm-laptop.png'
import ttWeb from '../../../resources/images/security.png'
import thinkcopyMobile from '../../../resources/images/thinkcopy-mobile.png'
import ttwebLaptop from '../../../resources/images/ttweb-laptop.png'
import ttwebMobile from '../../../resources/images/ttweb-mobile.png'
import tmLogonBlack from '../../../resources/images/think-markets-logo-black.png'
import tmLogonWhite from '../../../resources/images/think-markets-logo-white.png'

import levelOneApproved from '../../../resources/images/level-one-approved.svg'
import congratsLaptop from '../../../resources/images/congrats-laptop.svg'
import congratsMobile from '../../../resources/images/congrats-mobile.svg'

import forexVPSLogo from '../../Container/Dashboard/Vps/images/forex-vps-net.svg'
import beeksFCLogo from '../../Container/Dashboard/Vps/images/BeeksFC.png'
import forexVPSDarkLogo from '../../Container/Dashboard/Vps/images/forex-vps-net-dark.svg'
import beeksFCDarkLogo from '../../Container/Dashboard/Vps/images/BeeksFCDark.png'


import { useThemes } from "../../../utils/themes"


export type ImageName =
    'iphone' |
    'japanIphone' |
    'visa' |
    'mastercard' |
    'twitter' |
    'line' |
    'facebook' |
    'telegram' |
    'gmail' |
    'mt4Laptop' |
    'mt5Laptop' |
    'pammLaptop' |
    'thinkcopyMobile' |
    'ttwebLaptop' |
    'ttwebMobile' |
    'ttWeb' |
    'tmLogo' |
    'tmLogoLight' |
    'tmCondensedLogo' |
    'congratsLaptop' |
    'congratsMobile' |
    'levelOneApprovedLaptop' |
    'forexVPSLogo' |
    'beeksFCLogo' |
    'tradingView' |
    'tmLogon' |
    'dMT4' |
    'dMT5' |
    'mMT4' |
    'mMT5' |
    'dTT' |
    'mTT'


type ImageProps = ComponentProps<"img"> & {
    name?: ImageName | null
}

const Image: VFC<ImageProps> = ({ name, ...rest }) => {
    const { theme } = useThemes()

    if (!name) return null
    if (name === 'tmLogoLight') return <img alt={name} src={tmLogoLight} {...rest} />

    let path = ''
    if (theme === 'light') {
        switch (name) {
            case 'iphone': path = iphone; break
            case 'japanIphone': path = japanIphone; break
            case 'ttWeb': path = ttWeb; break
            case 'tmLogo': path = tmLogo; break
            case 'tmCondensedLogo': path = tmCondensedLogo; break
            case 'mt4Laptop': path = mt4Laptop; break
            case 'mt5Laptop': path = mt5Laptop; break
            case 'pammLaptop': path = pammLaptop; break
            case 'thinkcopyMobile': path = thinkcopyMobile; break
            case 'ttwebLaptop': path = ttwebLaptop; break
            case 'ttwebMobile': path = ttwebMobile; break
            case 'visa': path = visa; break
            case 'mastercard': path = mastercard; break
            case 'twitter': path = twitter; break
            case 'line': path = line; break
            case 'facebook': path = facebook; break
            case 'telegram': path = telegram; break
            case 'gmail': path = gmail; break
            case 'congratsLaptop': path = congratsLaptop; break
            case 'congratsMobile': path = congratsMobile; break
            case 'levelOneApprovedLaptop': path = levelOneApproved; break
            case 'forexVPSLogo': path = forexVPSLogo; break
            case 'beeksFCLogo': path = beeksFCLogo; break
            case 'tradingView': path = tradingView; break
            case 'tmLogon': path = tmLogonBlack; break
            case 'dMT4': path = dMT4; break
            case 'dMT5': path = dMT5; break
            case 'mMT4': path = mMT4; break
            case 'mMT5': path = mMT5; break
            case 'dTT': path = dTT; break
            case 'mTT': path = mTT; break
        }
    } else {
        switch (name) {
            case 'iphone': path = iphone; break
            case 'japanIphone': path = japanIphone; break
            case 'ttWeb': path = ttWeb; break
            case 'tmLogo': path = tmLogo; break
            case 'tmCondensedLogo': path = tmCondensedLogo; break
            case 'mt4Laptop': path = mt4Laptop; break
            case 'mt5Laptop': path = mt5Laptop; break
            case 'pammLaptop': path = pammLaptop; break
            case 'thinkcopyMobile': path = thinkcopyMobile; break
            case 'ttwebLaptop': path = ttwebLaptop; break
            case 'ttwebMobile': path = ttwebMobile; break
            case 'visa': path = visa; break
            case 'mastercard': path = mastercard; break
            case 'twitter': path = twitter; break
            case 'line': path = line; break
            case 'facebook': path = facebook; break
            case 'telegram': path = telegram; break
            case 'gmail': path = gmail; break
            case 'congratsLaptop': path = congratsLaptop; break
            case 'congratsMobile': path = congratsMobile; break
            case 'levelOneApprovedLaptop': path = levelOneApproved; break
            case 'forexVPSLogo': path = forexVPSDarkLogo; break
            case 'beeksFCLogo': path = beeksFCDarkLogo; break
            case 'tradingView': path = tradingView; break
            case 'tmLogon': path = tmLogonWhite; break
            case 'dMT4': path = dMT4; break
            case 'dMT5': path = dMT5; break
            case 'mMT4': path = mMT4; break
            case 'mMT5': path = mMT5; break
            case 'dTT': path = dTT; break
            case 'mTT': path = mTT; break
        }
    }

    return <img alt={name} src={path} {...rest} />
}

export default Image