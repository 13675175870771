import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useAppState, useDangerousHTML } from '../../../../../utils/hooks'
import { externalRoutes } from '../../../../../utils/routes'
import { useThemes } from "../../../../../utils/themes"
import CameraPhoto from '../../../../CameraPhoto'
import { hideGlobalLoader } from '../../../../GlobalLoader'
import Loader from "../../../../Loader"
import cn from 'classnames'
import cameraIconDark from './images/camera-dark.png'
import cameraIcon from './images/camera.png'
import checkmark from './images/checkmark.svg'
import errorIcon from './images/error.png'
import fail from './images/fail.png'
import goodIcon from './images/good.png'
import fileIconDark from './images/select-file-dark.png'
import fileIconLight from './images/select-file-light.png'
import user from './images/user.svg'
import styles from './mobile.module.scss'
import { useGBGUploader } from './useGBGUploader'


const ls = 'account:upload'

type ButtonStyle = 'solid' | 'outline'

const ScanIdMobilePage = () => {
    const [allowCamera, setAllowCamera] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const { t } = useTranslation()
    const { d } = useDangerousHTML()
    const { error: appError } = useAppState()
    const history = useHistory()
    const { theme } = useThemes()
    const iPhone = navigator.platform === 'iPhone'
    const iPhoneCameraRef = useRef<HTMLInputElement>(null)

    const {
        processing,
        postProcessing,
        submitDocument,
        fileSelectError,
        onFileSelect,
        step,
        setStep,
        side,
        takePhotoFromCamera,
        takePhotoFromIPhone,
        fileImage,
        currentFile,
    } = useGBGUploader({
        flow: 'mobile',
        startFrom: 'start',
        onSubmit: async (response) => {
            try {
                if (response.RequiredAction === 'NONE')
                    switch (response.CurrentResult) {
                        case "Pass":
                            window.location.href = externalRoutes.scanId.success
                            break
                        case "Failed":
                            window.location.href = externalRoutes.scanId.failure
                            break
                        case "ProcessingError":
                        case "NetworkError":
                            window.alert('Processing error')
                            setStep('selectFileOrCamera')
                            break
                        default:
                            window.alert(`Unknown required action ${response.RequiredAction}`)
                    }
            }
            catch (e) {
                console.log(e)
            }
        }
    })

    useEffect(() => {
        const lookupDevices = async () => {
            try {
                // await navigator.mediaDevices.getUserMedia({ video: true })
                // const devices = await navigator.mediaDevices.enumerateDevices()
                // setAllowCamera(Boolean(devices.find(d => d.kind === 'videoinput')))
                setAllowCamera(true)

            }
            catch (e) { }
            finally { hideGlobalLoader() }
        }
        lookupDevices()
    }, [])

    const subtitle = useMemo(() => {
        return <div className={styles.text}>{t(`${ls}.GBG.subtitle`)}</div>
    }, [t])

    const caption = useMemo(() => {
        return <div className={styles.caption}>
            {side === 'FRONTSIDE'
                ? t(`${ls}.GBG.title`)
                : t(`${ls}.GBG.upload.back`)}
        </div>
    }, [side, t])

    const divider = useMemo(() => {
        return <div className={styles.divider}>
            <span> {t('common:or')}</span>
        </div>
    }, [t])

    const fileButton = useCallback((label: string, className: string) => {
        const icon = <img src={theme === 'dark' ? fileIconDark : fileIconLight} alt="" />
        return <label className={className} htmlFor="file">
            {icon}
            {label}
            <input
                type="file"
                hidden
                name="filr"
                id="file"
                accept="image/jpeg,image/png,application/pdf"
                className={styles.inputfile}
                onChange={onFileSelect} />
        </label>
    }, [onFileSelect, theme])

    const cameraButton = useCallback((label: string, style: ButtonStyle) => {
        const className = style === 'outline' ? styles.outline : styles.green
        const icon = style === 'outline' && theme === 'light'
            ? <img src={cameraIconDark} alt="" />
            : <img src={cameraIcon} alt="" />
        return <button className={className} onClick={() => {
            if (iPhone) iPhoneCameraRef.current?.click()
            else setStep('takePhoto')
        }}>
            {icon}
            <div>{label} </div>
        </button>
    }, [iPhone, setStep, theme])


    const continueButton = useMemo(() => {
        return <button className={styles.green} onClick={submitDocument}>
            {t(`common:continue`)}
        </button>
    }, [submitDocument, t])

    const iPhoneCamera = useMemo(() =>
        <div className={styles.iPhoneVideo}>
            <label className={styles.video} htmlFor={`iPhone-file`}>
                <input ref={iPhoneCameraRef}
                    type='file'
                    hidden
                    name={`iPhone-file`}
                    id={`iPhone-file`}
                    accept='image/*,video/*'
                    className={styles.inputFile}
                    onChange={takePhotoFromIPhone}
                    capture='environment'
                />
            </label>
        </div>
        , [takePhotoFromIPhone])

    const takePhoto = useMemo(() => {
        return <>
            <div>
                {caption}
                {subtitle}
            </div>
            <CameraPhoto
                mobile={true}
                wrapperClassName={styles.cameraWrapper}
                onTakePhoto={takePhotoFromCamera} />
        </>
    }, [caption, subtitle, takePhotoFromCamera])

    const start = useMemo(() => {
        return <>
            <div>
                <div className={styles.userImage}>
                    <img src={user} alt="" />
                    <img src={checkmark} alt="" />
                </div>
                <div className={styles.caption}>
                    {t(`${ls}.GBG.start.title`)}
                </div>
                <div>{t(`${ls}.GBG.start.text`)}</div>
            </div>
            <button className={styles.green} onClick={() => setStep('selectFileOrCamera')}>
                {t(`${ls}.GBG.start.button`)}
            </button>
        </>
    }, [setStep, t])

    const select = useMemo(() => {
        return <>
            <div>
                {caption}
                {subtitle}
                <div className={styles.surface}>
                    {d(t(`${ls}.GBG.format`))}
                    {d(t(`${ls}.GBG.size`))}
                </div>
            </div>
            <div className={styles.buttons}>
                {fileButton(t(`${ls}.GBG.selectFile`), styles.outline)}
                {allowCamera && <>
                    {divider}
                    {cameraButton(t(`${ls}.GBG.takePhoto`), 'solid')}
                </>}
            </div>
        </>
    }, [caption, subtitle, d, t, fileButton, allowCamera, divider, cameraButton])

    const imagePreview = useMemo(() => {
        const error = <div className={styles.error}>
            <img src={errorIcon} alt="preview" />
            <div>{fileSelectError}</div>
        </div>
        const image = <div className={cn(styles.imagePreview, { [styles.fullScreen]: fullScreen })}
            onClick={() => setFullScreen(!fullScreen)}>
            <img src={fileImage} alt="preview" />
        </div>
        const pdf = <div className={cn(styles.pdfPreview, { [styles.fullScreen]: fullScreen })}
            onClick={() => setFullScreen(!fullScreen)}>
            <img src={goodIcon} alt="preview" />
            <div>{currentFile.current?.name}</div>
        </div>
        const pdfError = <div className={styles.pdfError}>
            <img src={errorIcon} alt="preview" />
            <div>{fileSelectError}</div>
        </div>
        const isPdf = currentFile.current?.type === 'application/pdf'
        if (fileSelectError) return isPdf
            ? pdfError
            : error
        return isPdf
            ? pdf : image
    }, [currentFile, fileImage, fileSelectError, fullScreen])

    const review = useMemo(() => {
        const error = <>
            <div className={styles.pdfError}>
                <img src={errorIcon} alt="preview" />
                <div>{appError
                    ? appError.type === 'custom'
                        ? appError.text
                        : appError.response
                    : ''
                }</div>
            </div>
        </>

        return <div className={styles.review}>
            <>
                <div className={styles.caption}>
                    {t(`${ls}.GBG.reviewing`)}
                </div>
                {appError ? error : <Loader />}
            </>
        </div>
    }, [appError, t])

    const previewFile = useMemo(() => {
        return processing || postProcessing
            ? review
            : <>
                <div>
                    {caption}
                    {subtitle}
                    {imagePreview}
                    {fileButton(t(`${ls}.GBG.changeFile`), styles.outline)}
                    {allowCamera && <>
                        {divider}
                        {cameraButton(t(`${ls}.takePhoto`), 'outline')}
                    </>}
                </div>
                <div className={styles.buttons}>
                    {continueButton}
                </div>
            </>

    }, [processing, postProcessing, review, caption, subtitle, imagePreview, fileButton, t, allowCamera, divider, cameraButton, continueButton])

    const previewPhoto = useMemo(() => {
        return processing || postProcessing
            ? review
            : <>
                <div>
                    {caption}
                    {subtitle}
                    {imagePreview}
                    {allowCamera && <>
                        {cameraButton(t(`${ls}.GBG.changePhoto`), 'outline')}
                        {divider}
                    </>}
                    {fileButton(t(`${ls}.GBG.uploadFile`), styles.outline)}
                </div>
                <div className={styles.buttons}>
                    {continueButton}
                </div>
            </>

    }, [processing, postProcessing, review, caption, subtitle, imagePreview, allowCamera, cameraButton, t, divider, fileButton, continueButton])

    const tryAgain = useMemo(() => {
        return <>
            <div className={styles.tryAgain}>
                <img src={fail} alt="" />
                <div className={styles.caption}>{t(`${ls}.GBG.tryAgain.title`)}</div>
                <div>{t(`${ls}.GBG.tryAgain.text3`)}</div>
            </div>
            <div>
                <button
                    onClick={() => setStep('selectFileOrCamera')}
                    className={styles.green}>
                    {t(`${ls}.GBG.tryAgain.button`)}
                </button>
                <button
                    onClick={() => history.push(externalRoutes.scanId.cancel)}
                    className={styles.outline}>
                    {t(`common:cancel`)}
                </button>
            </div>
        </>
    }, [history, setStep, t])


    const content = useMemo(() => {
        switch (step) {
            case 'start': return start
            case 'selectFileOrCamera': return select
            case 'takePhoto': return takePhoto
            case 'previewFile': return previewFile
            case 'previewPhoto': return previewPhoto
            case 'tryAgain': return tryAgain
            default: return <div></div>
        }
    }, [previewFile, previewPhoto, select, start, step, takePhoto, tryAgain])

    return <div className={styles.mobile}>
        {content}
        {iPhone && iPhoneCamera}
    </div>
}


export default ScanIdMobilePage