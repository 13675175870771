import { __STORAGE } from './../utils/enums'
import { RESTRICTED_DOMAINS } from './../utils/constants/DOMAINS'
import { apm } from '@elastic/apm-rum'
import { PayloadAction } from "@reduxjs/toolkit"
import queryString from "query-string"
import { all, call, delay, fork, put, race, select, take, takeEvery, takeLeading } from "redux-saga/effects"
import { appConfig, appInit } from '../config'
import i18n from '../i18n/i18n'
import Api, { Authorize } from "../utils/api"
import * as Enums from "../utils/enums"
import Routes from '../utils/routes'
import * as Types from "../utils/types"
import { APIResponse, ApplicationStatus, AppSubmitedParams, KYCProvider, Transaction, UserApplication } from '../utils/types'
import Utils from "../utils/utils"
import { UserProfile } from './../utils/types'
import * as Actions from "./actions"
import { getAccounts, getNotifications, getNotificationsMap, getOrganizationSettings } from './actions'
import * as ApiSlices from "./slices/api"
import {
    appError, changeLanguage, cleanupAppState,
    debugMode, docTypesLoaded, initCountries, initError, notificationMapLoaded, notificationsLoaded,
    organizationSettingsLoaded, sessionChecked, setAccounts, setAllowedPlatforms, setAllowThinkInvest, setApplicationApproval,
    setAppropriatenessLevel, setAppStatus, setAutoLogout,
    setIB, setIsDemoExpired, setIsSubscriptionTerminated, setKYCProvider,
    setLandingPage,
    setLastDeposit, setLoyalty, setMigration,
    setNewCreatedAccount, setNextTermsAndConditions,
    setPasswordResetToken, setPreferredLanguages,
    setQuestions,
    setRedirectedFromWTR, setShowFA2Warning, setSubscriptionStatus, setTwoFa,
    setUserAppInfo, setUserCountry, setUserInsidePortal, setUserReferrerStatus, userAppsLoaded, userEmailPreverified, userEmailVerified, userLoaded
} from './slices/app'
import { setProgressStep, setProgressSteps, setShowProgress } from "./slices/loader"
import { Store } from "./store"
import { cleanupIBState, setApplication as setIBApplication } from '../ib/redux/state'
import { saleforceLoyaltyRequest } from '../utils/salesforceApi'

function checkStatus(result: Types.APIResponse<any>): void {
    if (
        Array.isArray(result.payload) &&
        result.payload.length &&
        !["OK", "NOT_FOUND"].includes(result.payload[0].status)
    )
        throw Error(result.payload[0].status)
}

function getStatus(result: Types.APIResponse<any>): string {
    if (
        Array.isArray(result.payload) &&
        result.payload.length &&
        result.payload[0].status)
        return result.payload[0].status
    return ''
}

function cleanupStorage() {
    [Enums.__STORAGE.session,
    Enums.__STORAGE.token,
    Enums.__STORAGE.accessToken,
    Enums.__STORAGE.refreshToken,
    Enums.__STORAGE.validUntil,
    Enums.__STORAGE.user,
    Enums.__STORAGE.userLoginInfo,
    Enums.__STORAGE.accounts,
    Enums.__STORAGE.accountsLast,
    Enums.__STORAGE.lastClick,
    Enums.__STORAGE.u_pass,
    Enums.__STORAGE.notifications,
    Enums.__STORAGE.liveCongratsShown,
    Enums.__STORAGE.fa2].forEach(i => localStorage.removeItem(i))
}

function* changeLanguageSaga(action: PayloadAction<Enums.Language>) {
    let language: string = localStorage.language = action.payload
    switch (language) {
        case 'zh':
            language = 'zh-Hans'
            break
        case 'ms':
            language = 'ms-MY'
            break
        case 'pt':
            language = 'pt-BR'
            break
    }
    yield i18n.changeLanguage(language)
        .then(() => {
            document.title = i18n.t('common:portalTitle')
            document.documentElement.setAttribute("lang", language)
            if (language === 'ar') document.documentElement.setAttribute("dir", "rtl")
            else document.documentElement.setAttribute("dir", "ltr")
        })
}

function* preloadApp(transaction?: any): Generator<any, any, any> {
    const preloadAppSpan = transaction ? transaction.startSpan('preload App', 'app') : undefined
    const preloadAppRequestsSpan = transaction ? transaction.startSpan('Fetch user info, accounts, app info etc.', 'external.http') : undefined
    const response = yield call(Api.multilpeTFBO,
        [
            Api.getUserPayload(),
            Api.appInfoPayload(),
            Api.getIBApplicationPayload(),
            Api.checkApplicationStatusesPayload(),
            Api.getTransactionsPayload(),
            Api.getUserAppsPayload(),
            Api.isUserVerifiedPayload(Utils.loadEncryptedString(Enums.__STORAGE.user)!),
        ], Authorize.Yes
    )
    preloadAppRequestsSpan && preloadAppRequestsSpan.addLabels({ requestID: response.id })
    preloadAppRequestsSpan && preloadAppRequestsSpan?.end()
    checkStatus(response)
    const [user,
        appInfo,
        ibAppInfo,
        appStatuses,
        transactions,
        userApps,
        isUserVerified] = response.payload
    if (['VALIDATION_ERROR', 'SYS_ERR'].includes(appInfo.status)) {
        throw Utils.statusError(appInfo)
    }
    const profile = user.result as UserProfile
    yield put(Actions.getLoyalityStatus(profile.id))
    yield put(userLoaded(profile))
    yield put(setUserCountry(profile.country))
    const emailVerified = Utils.isDevEnv() ? true : isUserVerified.result
    yield put(userEmailVerified(emailVerified))
    if (ibAppInfo.status !== "NOT_FOUND" && ibAppInfo.status !== "SYS_ERR") {
        yield put(setIBApplication(ibAppInfo.result))
    } else yield put(setIBApplication(null))

    const statusesResult = (appStatuses.result || []) as Types.ApplicationStatusResponse[]
    // last item in the list is the latest application by date
    const statusResult = Array.isArray(statusesResult) ? statusesResult[statusesResult.length - 1] : null
    if (statusResult) {
        yield put(setAppStatus(statusResult.application_status as ApplicationStatus))
        yield put(setAppropriatenessLevel(statusResult.appropriateness_level))
        yield put(setApplicationApproval(statusResult.application_approval))
    }
    const apps = userApps.result as UserApplication[]
    if (apps.length > 0) {
        if (appInfo.status !== 'NOT_FOUND') yield put(setUserAppInfo(appInfo.result))
        yield put(userAppsLoaded(apps))
        const introducedBy = apps.find(a => a.introducedBy !== undefined)?.introducedBy
        if (introducedBy) try {
            const response = yield call(Api.isThinkInvestOptionDisabled, introducedBy, profile.id)
            yield put(setAllowThinkInvest(!response.payload[0].result))
        } catch { }
    }
    yield put(getOrganizationSettings())

    const query = queryString.parse(window.location.search)
    const parsedLang = query.lang as string
    const language = profile.preferredLanguage
        ? profile.preferredLanguage.language_code
        : 'en'
    yield put(changeLanguage(parsedLang || language))

    //accounts cache
    /*
    if (localStorage[Enums.__STORAGE.accounts])
        try {
            const accounts = JSON.parse(localStorage[Enums.__STORAGE.accounts])
            yield put(setAccounts(accounts))
        }
        catch { yield put(getAccounts()) }
    else yield put(getAccounts())
    */
    yield put(getAccounts())
    yield put(getNotificationsMap())
    const deposites: Transaction[] = Array.isArray(transactions.result)
        ? transactions.result.filter((x: Transaction) => (
            x.type === 'DEPOSIT'
        )) : []
    const last = deposites[deposites.length - 1]
    yield put(setLastDeposit(last ? last : null))
    yield put(Actions.checkSubscriptionStatus())
    yield put(Actions.checkUserReferrerStatus(profile.id))
    const shouldCheckTermsAndConditions = Utils.getStorageBoolean(Enums.__STORAGE.tAc)
    if (shouldCheckTermsAndConditions) {
        yield put(Actions.checkUserTermsAndConditions())
    }
    const wtr = yield select((state: Store) => state.App.redirectedFromWTR)
    const keepLogged = Utils.getStorageBoolean('keepLogged')
    if (!keepLogged && !wtr) {
        yield put(Actions.startCheckActivity())
    }

    preloadAppSpan && preloadAppSpan.end()
}

function* reloadAppInfoSaga(): Generator<any, any, any> {
    const info = yield call(Api.appInfo)
    const status = getStatus(info)
    if (status !== 'NOT_FOUND')
        yield put(setUserAppInfo(info.payload[0].result))
}

function* afterLogin(): Generator<any, any, any> {
    yield put(Actions.getMigrationStatus())
    yield put(Actions.checkSubscriptionStatus())
    yield put(Actions.checkUserTermsAndConditions())
    yield put(Actions.checkDemoExpiration())
}

function* loginSaga({ payload }: { payload: Types.LoginActionParams }): any {
    yield put(setAutoLogout(false))
    const transaction = apm.startTransaction('Login', 'custom', { managed: true })
    const loginSpan = transaction ? transaction.startSpan('Login saga', 'app', { blocking: true } as any) : undefined
    transaction && transaction.addLabels({ email: payload.email })
    if (payload.firstLogin) yield delay(1000)
    yield put(ApiSlices.login.actions.start())
    try {
        let fa2challenge = false
        const login: Types.AuthResponse & Types.ErrorAuthResponse = yield call(Api.Login, payload)
        if (login.code === "ASE-001") {
            yield put(ApiSlices.login.actions.error({
                error: "NOT_AUTHORIZED",
                response: login
            }))
            throw new Error("NOT_AUTHORIZED")
        }
        yield call(Api.setAuthTokens, login.tokens)
        const status = login.status as Types.LoginResponseStatus
        switch (status) {
            case 'OK':
            case 'PENDING_APPROVAL':
            case 'PENDING_REVIEW':
            case 'PENDING_ID_ADDRESS':
            case 'PENDING_ID':
            case 'PENDING_ADDRESS':
                break
            case 'TFA_REQUIRED':
                fa2challenge = true
                break
            default:
                ///LOCKED_OUT ???
                yield put(ApiSlices.login.actions.error({
                    error: status,
                    response: login
                }))
                throw new Error(status)
        }

        Utils.saveEncryptedString(Enums.__STORAGE.user, payload.email.toLowerCase())
        Utils.setStorageBoolean(!!payload.keepLogged, 'keepLogged')
        Utils.saveEncryptedString(Enums.__STORAGE.u_pass, payload.password)

        if (payload.firstLogin) {
            const ib = localStorage.getItem(__STORAGE.introducedBy)
            if (ib) {
                yield call(Api.addAttributeToUserProfile, { introducedBy: ib })
                localStorage.removeItem(__STORAGE.introducedBy)
            }
            yield call(preloadApp, transaction)
            yield put(setTwoFa({ checked: true, enabled: false, valid: true }))
        }
        else {
            if (!fa2challenge) {
                const fa2: Types.FA2Status = yield call(Api.get2FaStatus)
                yield put(setTwoFa({ checked: true, enabled: fa2.status === 'ENABLED', valid: true }))
                yield call(preloadApp, transaction)
                yield call(afterLogin)
                yield put(setShowFA2Warning(fa2.status === 'DISABLED'))
            }
            else
                yield put(setTwoFa({ checked: true, enabled: true, valid: false }))

        }
        const userProfile: Types.UserProfile | null = yield select((state: Store) => state.App.userProfile)

        window.dataLayer?.push({
            'event': 'User Login',
            'user id': userProfile?.id,
        })
        window.dataLayer?.push({
            'event': 'login',
            'user_id': userProfile?.id,
        })
        if (payload.firstLogin) {
            window.dataLayer?.push({
                'event': 'Application Started',
                'user id': userProfile?.id
            })
        }

        yield put(setQuestions([]))
        yield put(sessionChecked(true))
        yield put(ApiSlices.login.actions.success({}))
        // yield put(Actions.startKeepSession())
        // yield put(Actions.startRefreshAccounts())
    } catch (e: any) {
        const hideError = !payload.firstLogin &&
            e instanceof Error &&
            e.message === 'NOT_AUTHORIZED'
        if (!hideError) {
            if (e.type !== undefined) yield put(appError(e))
            else yield put(appError(Utils.customError(e.message)))
        } else apm.captureError(`Login: ${e.message}`)
        cleanupStorage()
    }
    loginSpan?.end()
    transaction?.end()
}


function* logoutSaga() {
    try {

        // Api.Logout()
    }
    catch (e) { }
    finally {
        yield put(Actions.stopRefreshAccounts())
        yield put(Actions.stopPullAppStatus())
        yield put(Actions.stopCheckActivity())
        yield put(ApiSlices.login.actions.clear())
        yield put(ApiSlices.logout.actions.success(true))
        cleanupStorage()
        yield call(Api.setTFBOAuth, '', '')
        yield call(Api.setAuthTokens, undefined)
        yield put(cleanupAppState())
        yield put(cleanupIBState())
        yield put(setUserInsidePortal(false))
    }
}

function* getUserSaga(): Generator<any, any, any> {
    try {
        const user = yield call(Api.getUser)
        checkStatus(user)
        yield put(userLoaded(user.payload[0].result))
    } catch (error: any) {
        apm.captureError(error)
    }
}

function* getLoyalityStatusSaga({ payload }: { payload: number }): Generator<any, any, any> {
    try {
        const loyaltyResponse = yield call(saleforceLoyaltyRequest, payload)
        yield put(setLoyalty(loyaltyResponse))
    } catch (error: any) {
    }
}

function* getMigrationStatusSaga() {
    while (true)
        try {
            yield take(Actions.getMigrationStatus.type)
            const migrationStatus: Types.APIResponse<Types.MigrationInfo> = yield call(Api.getMigrationStatus)
            checkStatus(migrationStatus)
            yield put(setMigration(migrationStatus.payload[0].result))
        } catch (error: any) {
            apm.captureError(error)
        }
}

function* checkUserTermsAndConditionsSaga(): Generator<any, any, any> {
    while (true)
        try {
            const [, second] = yield all([
                take(Actions.checkUserTermsAndConditions.type),
                take(setAccounts.type)
            ])
            const userProfile: Types.UserProfile | null = yield select((state: Store) => state.App.userProfile)
            const rawAccounts: Types.AccountStat[] = second.payload

            const hasDemoAccounts = rawAccounts.filter(a => a.account.type.toUpperCase() === 'DEMO').length > 0
            const hasLiveAccounts = rawAccounts.filter(a => a.account.type.toUpperCase() === 'LIVE').length > 0
            const isDemo = hasDemoAccounts && !hasLiveAccounts

            if (!isDemo && userProfile?.country.organization.name === Enums.PortalAccountDomain.TMJP) {
                const response = yield call(Api.multilpeTFBO,
                    [
                        Api.checkUserTermsAndConditionsPayload(),
                        Api.getLatestTermsAndConditionsPayload(),
                    ], Authorize.Yes
                )
                checkStatus(response)
                const [currentVersionResponse, latestTermsAndConditions] = response.payload as [Types.APIResponsePayload<Types.UserTermsAndConditions>, Types.APIResponsePayload<Types.LatestTermsAndConditions>]
                const isLoggedIn: boolean = yield select((state: Store) => state.App.loggedIn)
                if (currentVersionResponse.result.customerVersion !== latestTermsAndConditions.result.latestVersion && isLoggedIn) {
                    Utils.setStorageBoolean(true, Enums.__STORAGE.tAc)
                    yield put(setNextTermsAndConditions(latestTermsAndConditions.result))
                }
            }

        } catch (error: any) {
            apm.captureError(error)
        }
}

function* checkDemoExpirationSaga(): Generator<any, any, any> {
    while (true)
        try {
            const [, accountsResponse] = yield all([
                take(Actions.checkDemoExpiration.type),
                take(setAccounts.type),
            ])

            const userProfile: Types.UserProfile | null = yield select((state: Store) => state.App.userProfile)
            const userApps: Types.UserApplication[] = yield select((state: Store) => state.App.userApps)
            const rawAccounts: Types.AccountStat[] = accountsResponse.payload

            if (Utils.checkIfJapanDemoExpired(rawAccounts, userProfile, userApps)) {
                yield put(setIsDemoExpired(true))
            }

        } catch (error: any) {
            apm.captureError(error)
        }
}

function* checkSubscriptionStatusSaga(): Generator<any, any, any> {
    while (true)
        try {
            const [, accountsResponse] = yield all([
                take(Actions.checkSubscriptionStatus.type),
                take(setAccounts.type),
            ])
            const rawAccounts: Types.AccountStat[] = accountsResponse.payload
            yield call(logoutTerminatedUsersSaga, { payload: rawAccounts, type: 'logoutTerminatedUserIfNeeded' })
        } catch (error: any) {
            apm.captureError(error)
        }
}

function* checkUserReferrerStatusSaga(): Generator<any, any, any> {
    while (true)
        try {
            const [userIDpayload] = yield all([
                take(Actions.checkUserReferrerStatus.type),
            ])
            const userID: number = userIDpayload.payload
            const response: Types.APIResponse<Types.UserReferrerStatus> = yield call(Api.checkUserReferrerStatus, userID)
            checkStatus(response)
            yield put(setUserReferrerStatus(response.payload[0].result))
        } catch (error: any) {
            apm.captureError(error)
        }
}

function* logoutTerminatedUsersSaga({ payload: accounts }: PayloadAction<Types.AccountStat[]>): Generator<any, any, any> {
    try {
        const userProfile: Types.UserProfile | null = yield select((state: Store) => state.App.userProfile)
        const liveTTAccount = accounts.find(a => a.account.type.toUpperCase() === 'LIVE' && a.platformAccountType === 'ThinkTrader')

        if (userProfile?.country.organization.name === Enums.PortalAccountDomain.TMJP && liveTTAccount) {
            const response: Types.APIResponse<Types.GetSubscriptionStatusResponse> = yield call(Api.getSubscriptionStatus, { provider: 'BjpSub', accountNumber: liveTTAccount.account.accountNumber })
            const status = response.payload[0] ? response.payload[0].result.status : 'noRecord'
            yield put(setSubscriptionStatus(status))
            if (status === 'TERMINATED' || status === 'CANCELED') {
                yield put(Actions.logout())
                yield put(setIsSubscriptionTerminated(true))
            }
        }

    } catch (error: any) {
        apm.captureError(error)
    }
}

function* getUserAppsSaga(): Generator<any, any, any> {
    try {
        const userApps = yield call(Api.getUserApps)
        checkStatus(userApps)
        yield put(userAppsLoaded(userApps.payload[0].result))
    } catch (e) {
        console.error(e)
    }
}

function* getOrganizationSettingsSaga(action: any): Generator<any, any, any> {
    try {
        const response = yield call(Api.getOrganizationSettings, action.payload ? action.payload.id : undefined)
        checkStatus(response)
        yield put(organizationSettingsLoaded({ settings: response.payload[0].result }))
    } catch (e) {
        console.error(e)
    }
}

function* pullAppStatusWorker(): Generator<any, any, any> {
    let counter = 1
    const interval = appConfig.PULL_APP_STATUS_SEC || 30
    while (true) {
        if (counter > 1) {
            yield delay(interval * 1000)
        }
        try {
            counter++
            const apps: Types.UserApplication[] = yield select((state: Store) => state.App.userApps)
            const latestApp = apps.slice().sort((x, y) => Utils.sortDates('descend', x.createdDate, y.createdDate))[0]
            const response = yield call(Api.multilpeTFBO,
                [
                    Api.getUserAppsPayload(),
                    Api.checkApplicationStatusByIDPayload(latestApp.id),
                ], Authorize.Yes
            )
            checkStatus(response)
            const [userApps, appStatusResponse] = response.payload as [Types.APIResponsePayload<any>, Types.APIResponsePayload<Types.ApplicationStatusResponse>]
            const status = appStatusResponse.result.application_status as ApplicationStatus
            const applicationApproval = appStatusResponse.result.application_approval
            yield put(setAppStatus(status))
            yield put(setApplicationApproval(applicationApproval))
            yield put(userAppsLoaded(userApps.result))
            if (status === 'APPROVED') {
                yield put(Actions.getAccounts())
            }
        } catch (e) {
            console.error(e)
        }
    }
}

function* pullAppStatusSaga() {
    while (true) {
        try {
            yield take(Actions.startPullAppStatus.type)
            yield race([
                call(pullAppStatusWorker),
                take(Actions.stopPullAppStatus.type)
            ])
        } catch { }
    }
}

function* getNotificationsSaga({ payload }: { payload: boolean | undefined })
    : Generator<any, any, any> {
    try {
        if (payload || Utils.nowGreaterThen(Enums.__STORAGE.notifications, 30, 'seconds', 0)) {
            const result = yield call(Api.getUserNotifications)
            const notifications: Types.Notification[] = result.payload ? result.payload[0].result : []
            yield put(notificationsLoaded(notifications))
            Utils.writeTimestamp(Enums.__STORAGE.notifications)
        }
    } catch { }
}

function* fa2checkedSaga(): Generator<any, any, any> {
    try {
        const transaction = apm.startTransaction('FA2 checked', 'custom', { managed: true })
        yield call(preloadApp, transaction)
        yield put(setTwoFa({ checked: true, enabled: true, valid: true }))
        yield afterLogin
        transaction?.end()
    } catch (error) {
        console.error(error)
    }
}

function* getNotificationsMapSaga(): Generator<any, any, any> {
    try {
        const result = yield call(Api.getNotificationsMap)
        if (result.payload) {
            yield put(notificationMapLoaded(result.payload[0].result))
            yield delay(2000)
            yield put(getNotifications(true))
        }
    } catch { }
}

function* appFailedSaga() {
    yield put(setUserAppInfo({}))
    yield put(Actions.getUserApps())
}

function* corporateAppSubmitedSaga(action: PayloadAction<AppSubmitedParams>) {
    yield put(Actions.getUser())
    yield put(setNewCreatedAccount(action.payload.account))
    yield* getAccountsSaga()
}

function* appSubmitedSaga(action: PayloadAction<AppSubmitedParams>) {
    yield put(Actions.getUser())
    const transaction = apm.startTransaction('Submit App', 'custom', { managed: true })
    const submitSagaSpan = transaction ? transaction.startSpan('Submit App', 'app', { blocking: true } as any) : undefined
    const skipUploadDocs = (status: ApplicationStatus) => {
        return ['APPROVED', 'PENDING_REVIEW'].includes(status)
    }
    const { appStatus: initialStatus, countryId, account, applicationId } = action.payload

    yield put(setNewCreatedAccount(account))
    transaction && transaction.addLabels({ initialStatus })

    function* checkAppStatus(): Generator<any, any, any> {
        try {
            const getAppStatusSpan = transaction?.startSpan('getAppStatus', 'external.http')
            const result: Types.APIResponse<Types.ApplicationStatusResponse> = yield call(Api.checkApplicationStatusByID, applicationId)
            getAppStatusSpan?.end()
            checkStatus(result)
            return result.payload[0].result.application_status
        } catch {
            yield put(setAppStatus(initialStatus))
            return true
        }
    }

    function* setStatus(status: ApplicationStatus) {
        yield put(Actions.getUserApps())
        yield* getAccountsSaga()
        yield put(setAppStatus(status))
    }

    try {
        const apps: Types.UserApplication[] = yield select((state: Store) => state.App.userApps)
        const hasApproved = apps.find(a => a.applicationStatus === 'APPROVED')
        if (hasApproved) {
            yield* setStatus('APPROVED')
            window.navigateToPage && window.navigateToPage(Routes.dashboard.accounts.root)
        }
        else {
            const getKYCProviderSpan = transaction?.startSpan('getKYCProvider', 'external.http')
            const result: APIResponse<KYCProvider> = yield call(Api.getKYCProvider, countryId)
            getKYCProviderSpan?.end()
            checkStatus(result)

            const provider = result.payload[0].result
            transaction && transaction.addLabels({ provider })
            yield put(setKYCProvider(provider))
            let skip = provider.toUpperCase() !== 'THISISME' ||
                skipUploadDocs(initialStatus) || hasApproved


            if (!skip) {
                const steps = appConfig.VERIFICATION_ATTEMPTS || 3
                const interval = appConfig.VERIFICATION_INTERVAL_SEC || 3
                let step = 1
                yield put(setProgressSteps(steps + 1))
                yield put(setProgressStep(step))
                yield put(setShowProgress(true))
                for (let i = 0; i < steps; i++) {
                    const status = yield* checkAppStatus()
                    if (skipUploadDocs(status)) {
                        yield* setStatus(status)
                        skip = true
                        break
                    } else {
                        yield put(setProgressStep(++step))
                        if (i < steps - 1) {
                            const delaySpan = transaction?.startSpan('delay', 'app')
                            yield delay(interval * 1000)
                            delaySpan?.end()
                        }
                    }
                }
                yield put(setShowProgress(false))
                if (!skip) yield* setStatus(initialStatus)
            }
            else yield* setStatus(initialStatus)
        }
        if (initialStatus === 'APPROVED') yield put(Actions.getUserApps())
    }
    catch {
        yield* setStatus(initialStatus)
    }
    transaction && transaction.end()
    submitSagaSpan && submitSagaSpan.end()
}

function* CheckActivitySaga() {

    function* checkActivityWorker() {
        while (true) {
            try {
                yield delay(30 * 1000)
                const timeout: number = yield select((state: Store) => state.App.inactivityTimeout)
                if (Utils.nowGreaterThen(Enums.__STORAGE.lastClick, timeout, 'minutes')) {
                    yield put(Actions.logout())
                    yield put(setAutoLogout(true))
                    setTimeout(() =>
                        window.navigateToPage && window.navigateToPage(Routes.account.login), 1000)
                }

            }
            catch { }
        }
    }

    while (true) {
        yield take(Actions.startCheckActivity.type)
        yield race([
            call(checkActivityWorker),
            take(Actions.stopCheckActivity.type)
        ])
    }
}

/*
function* keepRefreshingAccounts() {
    function* keepRefreshingAccountsWorker(interval: number): Generator<any, any, any> {
        while (true) {
            try {
                yield delay(interval)
                const applications: Types.UserApplication[] | null = yield select((state: Store) => state.App.userApps)
                if (Utils.filterOpenApps(applications)?.length) {
                    yield put(Actions.getUserApps())
                }
                const response = yield call(Api.getAccountStat)
                checkStatus(response)
                yield put(setAccounts(response.payload.result.data))
            } catch { }
        }
    }

    while (true) {
        yield take(Actions.startRefreshAccounts.type)
        yield race([
            call(keepRefreshingAccountsWorker, 60000),
            take(Actions.stopRefreshAccounts.type)
        ])
    }
}
*/
function* getQuestionsSaga(): Generator<any, any, any> {
    try {
        let id = 0
        const userProfile: Types.UserProfile | null = yield select((state: Store) => state.App.userProfile)
        if (userProfile) id = userProfile.organization.id
        else {
            //not logged in yet
            const orgs: Types.Organization[] = yield select((state: Store) => state.App.organizations)
            const countryId = yield select((state: Store) => state.App.userCountry?.organization?.id)
            const onboardingDomain = yield select((state: Store) => state.App.onboardingDomain)
            const onboardingId = orgs.find(o => o.name === onboardingDomain)?.id
            id = onboardingId || countryId
        }
        const response = yield call(Api.getQuestions, id)
        yield put(setQuestions(response.payload[0].result))
    } catch { }
}

function apiCall(slice: any, apiCall: any) {
    return function* (action: any): Generator<any, any, any> {
        let count = 3, success = false
        yield put(slice.actions.start(action.payload))
        while (count && !success)
            try {
                const result = yield call(apiCall, action.payload)
                checkStatus(result)
                yield put(slice.actions.success({ result: result, request: action.payload }))
                success = true
            } catch (e) {
                yield put(slice.actions.error({ error: e, request: action.payload }))
                count--
                yield delay(3 * 1000)
            }
    }
}

function* onStartSaga(): Generator<any, any, any> {

    function* initializationError(e: any): Generator<any, any, any> {
        console.log(e)
        Utils.postMessageToWTR({ message: 'restart' })
        yield put(initError(e))
        yield put(cleanupAppState())
        yield put(cleanupIBState())
        cleanupStorage()
        yield put(sessionChecked(false))

    }

    function* setLanguage(languages?: Types.PreferredLanguage[], country_code?: string): Generator<any, any, any> {
        const query = queryString.parse(window.location.search)
        const queryLang = languages?.find(x => x.language_code === query?.lang)
        const defaultLang: string = queryLang?.language_code || localStorage.language || Utils.getLanguage(country_code)
        yield put(changeLanguage(defaultLang))
    }

    function* initFromServer(): Generator<any, any, any> {
        try {
            const response = yield call(Api.multilpeTFBO,
                [
                    Api.getIPPayload(),
                    Api.getCountriesPayload(true),
                    Api.getPreferredLanguagesPayload(),
                    Api.getDocumentTypesPayload(),
                ], Authorize.No)
            checkStatus(response)
            const [ip, countries, languages, docTypes] = response.payload
            Utils.saveEncryptedObject(Enums.__STORAGE.init, {
                ip: ip.result,
                countries: countries.result,
                languages: languages.result,
                docTypes: docTypes.result,
            })

            yield put(initCountries({ ip: ip.result, countries: countries.result }))
            yield put(setPreferredLanguages(languages.result))
            yield put(docTypesLoaded(docTypes.result))
            yield* setLanguage(languages.result, (ip.result as Types.GetIPResponse)?.country_code)
        }
        catch (e) { yield initializationError(e) }
    }
    function* initFromStorage(): Generator<any, any, any> {
        const { ip, countries, languages, docTypes } = Utils.loadEncryptedObject<any>(Enums.__STORAGE.init)
        yield put(initCountries({ ip: ip, countries: countries }))
        yield put(setPreferredLanguages(languages))
        yield put(docTypesLoaded(docTypes))
        yield* setLanguage(languages, (ip as Types.GetIPResponse)?.country_code)
    }
    function* ssoLogin(parsedEmail: string, parsedToken: string, query: queryString.ParsedQuery<string>): Generator<any, any, any> {
        try {
            const response: Types.APIResponse<Types.ValidateSsoTokenResponse> = yield call(Api.validateSsoToken, {
                ssoCode: parsedToken,
            })
            if (!response) throw new Error()
            Utils.saveEncryptedString(Enums.__STORAGE.user, parsedEmail)
            const fa2: Types.FA2Status = yield call(Api.get2FaStatus)
            yield put(setTwoFa({ checked: true, enabled: fa2.status === 'ENABLED', valid: true }))
        } catch (e) {
            console.error('>> error validation sso', e)
            yield put(cleanupAppState())
            yield put(cleanupIBState())
            cleanupStorage()
            yield put(sessionChecked(false))
        }
    }
    const transaction = apm.startTransaction('On start saga', 'custom')
    try {
        yield appInit
        // When user is coming from WTR with ssoToken in GET params (SSO login)
        const query = queryString.parse(window.location.search)
        const parsedEmail = query.email as string
        const parsedToken = query.ssoToken as string
        const parsedReferrer = query.referrer as string
        const parsedWtrApp = query.wtrApp as string
        const parsedSource = query.source as string
        const parsedKeepmesignedin = query.keepmesignedin as 'yes' | 'no'
        const pageView = localStorage.getItem('pageView')
        const referrer = queryString.parse(document.referrer)
        if (query.aid || referrer.aid) {
            yield put(setIB(
                {
                    afsAid: (query.aid || referrer.aid) as string,
                }
            ))
        }
        if (query.debug)
            yield put(debugMode(String(query.debug).toUpperCase() === "TRUE"))
        if (query.emailVerificationStatus)
            yield put(userEmailPreverified(String(query.emailVerificationStatus).toUpperCase() === "TRUE"))
        if (query.pwrt)
            yield put(setPasswordResetToken(query.pwrt))
        if (parsedSource)
            sessionStorage.setItem('parsedSource', parsedSource)
        if (query.platform)
            localStorage.setItem(Enums.__STORAGE.platform, String(query.platform).toUpperCase())
        if (query.utm_source) {
            const utm_source = String(query.utm_source)
            localStorage.setItem(Enums.__STORAGE.utm_source, utm_source)
            if (utm_source.toUpperCase() === 'TRADINGVIEW')
                localStorage.setItem(Enums.__STORAGE.platform, 'THINKTRADER')
        }


        const cleanedQuery = Object.entries(query).reduce((prev, curr) => {
            if (
                curr[0] === 'email'
                || curr[0] === 'ssoToken'
                || curr[0] === 'referrer'
                || curr[0] === 'keepmesignedin'
                || curr[0] === 'pwrt'
                || curr[0] === 'theme'
                || curr[0] === 'platform'
                || curr[0] === 'utm_source'
            ) {
                return prev
            }
            const currentParam = `${curr[0]}=${curr[1]}`
            return prev ? prev + `&${currentParam}` : currentParam
        }, '')
        const urlForReplacement = cleanedQuery
            ? `${window.location.pathname}?${cleanedQuery}`
            : `${window.location.pathname}${window.location.hash}`
        window.history.replaceState({}, '', urlForReplacement)

        if (parsedKeepmesignedin) {
            Utils.setStorageBoolean(parsedKeepmesignedin === 'yes', 'keepLogged')
        }

        if (parsedEmail && parsedToken) {
            yield put(Actions.logout())
            yield* ssoLogin(parsedEmail, parsedToken, query)
        }
        else yield put(setLandingPage({ path: window.location.pathname, query: cleanedQuery }))

        if (parsedReferrer === 'wtr') {
            yield put(setRedirectedFromWTR(true))
        }

        if (parsedWtrApp === 'true') {
            sessionStorage.setItem('wtrApp', parsedWtrApp)
        }

        if (pageView === 'WTR') {
            yield put(setRedirectedFromWTR(true))
            localStorage.removeItem('pageView')
        }

        const restrictions = RESTRICTED_DOMAINS.find(d => d.host === window.location.hostname)
        if (restrictions) yield put(setAllowedPlatforms(restrictions.platforms))

        if (!Enums.__STORAGE.init) {
            yield* initFromServer()
        } else {
            try {
                yield* initFromStorage()
                yield fork(initFromServer)
            }
            catch (e) {
                yield* initFromServer()
            }
        }
        try {
            const session = yield call(Api.VerifyTokens)
            if (!session) throw new Error('session expired')
            const fa2 = Utils.loadEncryptedObject<Types.FA2>(Enums.__STORAGE.fa2)
            if (fa2.enabled !== undefined &&
                fa2.checked !== undefined &&
                fa2.valid !== undefined)
                yield put(setTwoFa({
                    enabled: fa2.enabled,
                    checked: fa2.checked,
                    valid: fa2.valid
                }))
            else throw new Error('Invalid stored 2FA object')
            yield* preloadApp(transaction)
            yield put(sessionChecked(true))
            // yield put(Actions.startRefreshAccounts())
        }
        catch (e) {
            yield put(cleanupAppState())
            yield put(cleanupIBState())
            cleanupStorage()
            yield put(sessionChecked(false))
        }
    }
    catch (e) {
        yield initializationError(e)
    }
    transaction?.end()
}

function* getAccountsSaga(): Generator<any, any, any> {
    try {
        const transaction = apm.startTransaction('Get accounts', 'custom', { managed: true })
        const getAccountSpan = transaction?.startSpan('getAccount', 'external.http')
        yield put(ApiSlices.getAccounts.actions.start())
        const result = yield call(Api.getAccountStat)
        checkStatus(result)
        yield put(setAccounts(result.payload[0].result.data))
        yield put(ApiSlices.getAccounts.actions.success(true))
        //accounts cache
        // localStorage[Enums.__STORAGE.accounts] = JSON.stringify(result.payload[0].result.data)
        // Utils.writeTimestamp(Enums.__STORAGE.accountsLast)
        getAccountSpan?.end()
        getAccountSpan?.end()
        transaction?.end()

    } catch (e: any) {
        yield put(ApiSlices.getAccounts.actions.error(e.message))
        yield put(appError(Utils.customError(e.message)))
    }
}

function* launchAppPreload(): Generator<any, any, any> {
    try {
        const transaction = apm.startTransaction('Simplified onboarding creation', 'custom', { managed: true })
        yield put(sessionChecked(true))
        yield call(preloadApp, transaction)
        yield put(setTwoFa({ checked: true, enabled: false, valid: true }))
    } catch (error) {
        console.error(error)
    }
}

function* watchAsync() {
    yield takeEvery(changeLanguage, changeLanguageSaga)
    yield takeLeading(Actions.login, loginSaga)
    yield takeLeading(Actions.logout, logoutSaga)
    yield takeLeading(Actions.getUser, getUserSaga)
    yield takeLeading(Actions.getUserApps, getUserAppsSaga)
    yield takeLeading(Actions.reloadAppInfo, reloadAppInfoSaga)
    yield takeLeading(Actions.getOrganizationSettings, getOrganizationSettingsSaga)
    yield takeLeading(Actions.appSubmited, appSubmitedSaga)
    yield takeLeading(Actions.corporateAppSubmited, corporateAppSubmitedSaga)
    yield takeEvery(Actions.appFailed, appFailedSaga)
    yield takeLeading(Actions.getNotifications, getNotificationsSaga)
    yield takeLeading(Actions.getQuestions, getQuestionsSaga)
    yield takeEvery(Actions.getNotificationsMap, getNotificationsMapSaga)
    yield takeEvery(Actions.getLoyalityStatus, getLoyalityStatusSaga)
    yield takeEvery(Actions.fa2checked, fa2checkedSaga)
    yield takeLeading(Actions.getAccounts, getAccountsSaga)
    yield takeLeading(Actions.logoutTerminatedUserIfNeeded, logoutTerminatedUsersSaga)
    yield takeLeading(Actions.launchAppPreload, launchAppPreload)
    yield takeLeading(Actions.getBankAccounts,
        apiCall(ApiSlices.getBankAccounts, Api.getBankAccounts))
    yield takeLeading(Actions.getAppStatus,
        apiCall(ApiSlices.getAppStatus, Api.checkApplicationStatusByID))
    yield takeLeading(Actions.sendLastField,
        apiCall(ApiSlices.sendLastField, Api.sendLastField))
}

export default function* rootSaga() {
    yield fork(watchAsync)
    yield fork(CheckActivitySaga)
    // yield fork(keepRefreshingAccounts)
    yield fork(pullAppStatusSaga)
    yield fork(getMigrationStatusSaga)
    yield fork(checkUserTermsAndConditionsSaga)
    yield fork(checkDemoExpirationSaga)
    yield fork(checkSubscriptionStatusSaga)
    yield fork(checkUserReferrerStatusSaga)
}

export { onStartSaga }


