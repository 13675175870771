import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { useShouldShowIBportal } from "../../../../ib/utils/hooks"
import { logout } from "../../../../redux/actions"
import { useAccounts, useAppState, useOrganization, useUserApplications } from '../../../../utils/hooks'
import appRoutes from '../../../../utils/routes'
import { useRedirect } from "../../../../utils/useRedirect"
import ModalConfirm from "../../../Presentational/ModalConfirm"
import SideBar from "../../../Presentational/SideBar"
import WtrSideBar from "../../../Presentational/SideBar/WtrSideBar"
import { useCompleteOnboardingModal } from "../Deposit/components/CompleteOnboardingNotice"
import { useMenuEntries } from "./items"
import styles from './style.module.scss'
import { DashboardMenuItem } from "./utils"



const prefix = 'dashboard.sidebar'

const DashboardSideBar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { language, redirectedFromWTR } = useAppState()
  const shouldShowIBportal = useShouldShowIBportal()
  const { isDemo } = useAccounts()
  const { incompleteApplication, hasOngoingApplication, simplifiedAppNotApprovedYet } = useUserApplications()
  const { isTMCY } = useOrganization()
  const { noticeModal, setNoticeModalOpened } = useCompleteOnboardingModal('sidebar')
  const { redirectToRegisterFormStep } = useRedirect()
  const [showDemoFundModal, setshowDemoFundModal] = useState(false)
  const menuItems = useMenuEntries()

  const handleItemClick = useCallback((item: DashboardMenuItem) => {
    window.dataLayer?.push({
      'event': 'custom_event',
      'event_name': 'left_navigation',
      'navigation_link_name': item.name
    })

    switch (item.name) {
      case 'funds':
        if (isDemo && (incompleteApplication || !hasOngoingApplication)) setshowDemoFundModal(true)
        break
      case 'withdraw':
        if (simplifiedAppNotApprovedYet) setNoticeModalOpened(true)
        break
      case 'transfer':
        if (simplifiedAppNotApprovedYet) setNoticeModalOpened(true)
        break
      case 'faq':
        if (language === 'en' && !isTMCY)
          window.open('https://support.thinkmarkets.com/hc/en-gb', '_blank')
        else
          history.push(appRoutes.dashboard.support.faq)
        break
      case 'logout':
        dispatch(logout())
        history.push(appRoutes.account.login)
        break
      case 'ib':
        if (shouldShowIBportal)
          history.push(appRoutes.ib.dashboard)
        break
      case 'partnerships':
        history.push(appRoutes.dashboard.partnerships)
        break
    }
  }, [dispatch, hasOngoingApplication, shouldShowIBportal, history, incompleteApplication, isDemo, isTMCY, language, setNoticeModalOpened, simplifiedAppNotApprovedYet])

  const handleProceedClick = useCallback(() => {
    setshowDemoFundModal(false)
    if (incompleteApplication) {
      redirectToRegisterFormStep()
    } else {
      history.push(appRoutes.account.createAccount.live)
    }
  }, [history, incompleteApplication, redirectToRegisterFormStep])

  const wtrItems = menuItems.find(item => item.name === 'funds')

  return (
    <>

      {redirectedFromWTR ? (
        <WtrSideBar
          handleItemClick={handleItemClick}
          languagePrefix={`${prefix}.funds`}
          items={wtrItems?.children || []}
        />
      ) : (
        <SideBar
          handleItemClick={handleItemClick}
          languagePrefix={prefix}
          items={menuItems}
        />
      )}
      {isDemo && showDemoFundModal && (
        <ModalConfirm
          showCross
          isOpen
          icon="attentionWarning"
          title=""
          onModalClose={() => setshowDemoFundModal(false)}
        >
          <div>{incompleteApplication ? t(`${prefix}.funds.demoWarning.textIncomplete`) : t(`${prefix}.funds.demoWarning.text`)}</div>
          <div className={styles.warningButtons}>
            <button
              className="link"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setshowDemoFundModal(false)
              }}
            >
              {t(`${prefix}.funds.demoWarning.later`)}
            </button>

            <button
              className="green"
              type="submit"
              onClick={handleProceedClick}
            >
              {incompleteApplication ? t(`dashboard.account.goLive.completeApplication`) : t(`${prefix}.funds.demoWarning.create`)}
            </button>
          </div>
        </ModalConfirm>
      )}
      {noticeModal}
    </>
  )
}

export default DashboardSideBar
