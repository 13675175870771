import { PortalAccountDomain } from "../enums"

export const CONTACT_US_LINK_AU = 'https://www.thinkmarkets.com/au/support/contact-us/'
export const CONTACT_US_LINK_TMCY = 'https://www.thinkmarkets.com/eu/support/who-we-are/'
export const AU_SUPPORT_LINK = 'https://www.thinkmarkets.com/au/support/'

export const UK_CONTACT_PHONE = '+44 203 514 2374'
export const TMCY_CONTACT_PHONE = '+357 2 5262149'
export const AU_CONTACT_PHONE = '+61 3 9093 3400'
export const TFSA_CONTACT_PHONE = '+27 10 446 5933'
export const TMBM_CONTACT_PHONE = '+61 3 9093 3400'
// export const INTERNATIONAL_CONTACT_PHONE = '+1 855 567 9426'
type ContactPhones = Record<PortalAccountDomain, string>
export const CONTACT_PHONES: ContactPhones = {
  [PortalAccountDomain.UK]: UK_CONTACT_PHONE,
  [PortalAccountDomain.TMCY]: TMCY_CONTACT_PHONE,
  [PortalAccountDomain.TMEU]: UK_CONTACT_PHONE,
  [PortalAccountDomain.TMJP]: UK_CONTACT_PHONE,
  [PortalAccountDomain.AU]: AU_CONTACT_PHONE,
  [PortalAccountDomain.TMNZ]: AU_CONTACT_PHONE,
  [PortalAccountDomain.TFSA]: TFSA_CONTACT_PHONE,
  [PortalAccountDomain.TMBM]: TMBM_CONTACT_PHONE,
  [PortalAccountDomain.TMLC]: TMBM_CONTACT_PHONE,
  [PortalAccountDomain.TMSY]: TMBM_CONTACT_PHONE,
  [PortalAccountDomain.Unknown]: AU_CONTACT_PHONE,
}

export const VPS_TC_LINKS: ContactPhones = {
  [PortalAccountDomain.UK]: 'https://www.thinkmarkets.com/uk/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMCY]: 'https://www.thinkmarkets.com/uk/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMEU]: 'https://www.thinkmarkets.com/uk/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMJP]: 'https://www.thinkmarkets.com/uk/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.AU]: 'https://www.thinkmarkets.com/au/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMNZ]: 'https://www.thinkmarkets.com/au/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TFSA]: 'https://www.thinkmarkets.com/za/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMBM]: 'https://www.thinkmarkets.com/en/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMLC]: 'https://www.thinkmarkets.com/en/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.TMSY]: 'https://www.thinkmarkets.com/en/tools/forex-vps/vps-terms-and-conditions/',
  [PortalAccountDomain.Unknown]: 'https://www.thinkmarkets.com/au/tools/forex-vps/vps-terms-and-conditions/',
}
