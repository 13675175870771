import FilePonyfill from '@tanker/file-ponyfill'
import { validateImage } from "image-validator"
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { getUserApps } from '../../../../../redux/actions'
import queryString from 'query-string'
import { appError, setAccounts, setAppStatus } from '../../../../../redux/slices/app'
import api from '../../../../../utils/api'
import { GBGScanTokensResponse } from '../../../../../utils/types'
import Utils from '../../../../../utils/utils'
import { acceptedFormats, DocSide, JorneyStep, MAX_FILE_SIZE, StatusAfterKYC, UploadData, UploadResponse } from './types'

const ls = 'account:upload'
const INTERVAL = 3000
const TIMEOUT = 2000

export const useGBGUploader = ({ startFrom, onSubmit, flow }:
    {
        flow: 'web' | 'mobile'
        startFrom: JorneyStep
        onSubmit: (response: Partial<UploadResponse>) => void
    }) => {
    const { t } = useTranslation()
    const [count, setCount] = useState(2)
    const [processing, setProcessing] = useState(false)
    const [initializationError, setInitializationError] = useState(false)
    const [processingError, setProcessingError] = useState(false)
    const [postProcessing, setPostProcessing] = useState(false)
    const [fileSelectError, setFileSelectError] = useState<string>()
    const [step, setStep] = useState<JorneyStep>(startFrom)
    const [fileImage, setfileImage] = useState<string>()
    const currentFile = useRef<File>()
    const [side, setSide] = useState<DocSide>('FRONTSIDE')
    const dispatch = useDispatch()
    const tokens = useRef<Partial<GBGScanTokensResponse>>()
    const location = useLocation()

    useEffect(() => {
        const init = async () => {
            if (flow === 'mobile') {
                const query = queryString.parse(location.search) as Partial<GBGScanTokensResponse>
                tokens.current = {
                    token: query.token,
                    journeyId: query.journeyId,
                    journeyDefinitionId: query.journeyDefinitionId,
                    applicationId: +(query.applicationId ?? 0)
                }
                if (!tokens.current?.token) {
                    setInitializationError(true)
                }
            }
            else {
                try {
                    const tokensResponse = await api.getGBGScanTokens()
                    tokens.current = tokensResponse.payload[0].result
                    if (!tokens.current?.token) {
                        setInitializationError(true)
                    }
                }
                catch (e) { console.log('getGBGScanTokens error ', e) }
            }
        }
        init()
    }, [flow, location.search])

    const submitDocument = useCallback(async () => {
        if (tokens.current && fileImage) {
            try {
                setProcessing(true)
                const image = fileImage.replace(/^.*base64,/, '')
                const data: Partial<UploadData> = {
                    AdditionalData: [
                        {
                            Name: "JourneyDefinitionId",
                            Value: tokens.current.journeyDefinitionId || ''
                        },
                        {
                            Name: "applicationId",
                            Value: tokens.current.applicationId || ''
                        }
                    ],
                    "metadata": [
                        {
                            Name: "Capture Type",
                            Value: "File Upload",
                            step: side,
                            type: "Simple"
                        },
                    ],
                    InputImages: [
                        {
                            Data: image,
                            ImageFormat: currentFile.current?.type || '',
                            Name: "WhiteImage",
                            inputFormat: 0
                        }
                    ]
                }
                if (tokens.current.journeyId)
                    data.PersonEntryId = tokens.current.journeyId
                const url = Utils.isTestEnv()
                    ? 'https://poc.idscan.cloud/idscanenterprisesvc/journey/upload'
                    : 'https://prod.idscan.cloud/idscanenterprisesvc/journey/upload'
                const request = await fetch(url,
                    {
                        method: 'post',
                        headers: {
                            'Content-Type': "application/json",
                            "Authorization": tokens.current.token || ''
                        },
                        body: JSON.stringify(data)

                    })
                const response = await request.json() as UploadResponse
                tokens.current.journeyId = response.PersonEntryId

                switch (response.RequiredAction) {
                    case 'NONE':
                        break
                    case 'FRONTSIDE:SECONDSCAN':
                        setCount(2)
                        setStep('tryAgain')
                        break
                    case 'FRONTSIDE:THIRDSCAN':
                        setCount(1)
                        setStep('tryAgain')
                        break
                    case 'BACKSIDE':
                        setSide('BACKSIDE')
                        setStep('selectFileOrCamera')
                        break
                    case 'BACKSIDE:SECONDSCAN':
                        setCount(2)
                        setStep('tryAgain')
                        break
                    case 'BACKSIDE:THIRDSCAN':
                        setCount(1)
                        setStep('tryAgain')
                        break
                }

                onSubmit(response)

            }
            catch (e) {
                console.log('submitting error ', e)
                setProcessingError(true)
                onSubmit(
                    {
                        RequiredAction: 'NONE',
                        CurrentResult: 'NetworkError',
                    }
                )
            }
            finally { setProcessing(false) }
        }
        else window.alert('no tokens or fileImage, upload not possible')


    }, [fileImage, onSubmit, side])

    /*
    ///DEBUG
    const submitDocument = useCallback(async () => {
        setProcessing(true)
        setTimeout(() => {
            const response: UploadResponse = {
                PersonEntryId: '123',
                EntryDateTime: new Date(),
                RequiredAction: 'NONE',
                CurrentResult: 'Pass',
                HighLevelResult: 'Pass',
                EntryImages: [],
                IsFinished: true,
                RequestId: '123',
                HasError: false,
                ResultDetails: [],
            }
            setProcessing(false)
            onSubmit(response)
        }, 2000)
    }, [onSubmit])
*/

    const onFileSelect = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const f = e.target.files[0]
            currentFile.current = new FilePonyfill([f], `${f.name}`, { type: f.type })
            if (!acceptedFormats.includes(f.type)) {
                setFileSelectError(t(`${ls}.invalidFileType`, { file: f.name }))
                setStep('previewFile')
            } else if (f.size > MAX_FILE_SIZE) {
                setFileSelectError(t(`${ls}.GBG.sizeError`, { file: f.name }))
                setStep('previewFile')
            } else {
                const isValidImage = /pdf/.test(f.type)
                    ? true
                    : await validateImage(f)
                if (isValidImage) {
                    setFileSelectError('')
                    var reader = new FileReader()
                    reader.onloadend = (e) => {
                        setfileImage(e.target!.result as string)
                        setStep('previewFile')
                    }
                    reader.readAsDataURL(f)
                } else {
                    setFileSelectError(t(`${ls}.invalidImage`, { file: f.name }))
                    setStep('previewFile')
                }
            }
        }
    }, [t])

    const takePhotoFromIPhone = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const f = e.target.files[0]
            currentFile.current = new FilePonyfill([f], `${f.name}`, { type: f.type })
            setFileSelectError('')
            var reader = new FileReader()
            reader.onloadend = (e) => {
                setfileImage(e.target!.result as string)
                setStep('previewFile')
            }
            reader.readAsDataURL(f)
        }
    }, [])

    const takePhotoFromCamera = useCallback((f: File) => {
        currentFile.current = new FilePonyfill([f], `${f.name}_${Date.now()}`, { type: f.type })
        setFileSelectError('')
        var reader = new FileReader()
        reader.onload = (e) => {
            setfileImage(e.target!.result as string)
            setStep('previewPhoto')
        }
        reader.readAsDataURL(f)
    }, [])

    const waitSuccessFinishing = useCallback(() => {
        return new Promise((resolve, reject) => {
            const waitForAccount = () => {
                const interval = setInterval(async () => {
                    try {
                        const response = await api.getAccountStat()
                        api.checkTFBOResponse(response)
                        const accounts = response.payload[0].result.data
                        if (accounts.some(a => a.account.type === 'LIVE')) {
                            clearInterval(interval)
                            dispatch(setAccounts(accounts))
                            dispatch(setAppStatus('APPROVED'))
                            setTimeout(() => {
                                resolve('APPROVED')
                            }, 1000)
                        }
                    }
                    catch (e) {
                        clearInterval(interval)
                        reject(e)
                        console.log(e)
                        dispatch(appError(Utils.customError('Error getting account status')))
                    }
                }, INTERVAL)
            }

            const waitForStatus = () => {
                const interval = setInterval(async () => {
                    try {
                        const response = await api.checkApplicationStatuses()
                        api.checkTFBOResponse(response)
                        const status = response.payload[0].result[0].application_status as StatusAfterKYC
                        if (status !== 'PENDING_KYC') {
                            clearInterval(interval)
                            dispatch(getUserApps())
                            if (status !== 'APPROVED') {
                                dispatch(setAppStatus(status))
                                resolve(status)
                            }
                            else {
                                setTimeout(waitForAccount, TIMEOUT)
                            }
                        }
                    }
                    catch (e) {
                        clearInterval(interval)
                        reject(e)
                        console.log(e)
                        dispatch(appError(Utils.customError('Error getting application status')))
                    }
                }, INTERVAL)
            }
            setPostProcessing(true)
            setTimeout(waitForStatus, TIMEOUT)

        })
    }, [dispatch])

    return {
        processing,
        postProcessing,
        submitDocument,
        fileSelectError,
        onFileSelect,
        step,
        setStep,
        fileImage,
        setfileImage,
        currentFile,
        side,
        count,
        setSide,
        processingError,
        initializationError,
        takePhotoFromCamera,
        takePhotoFromIPhone,
        waitSuccessFinishing
    }
}

