import FilePonyfill from '@tanker/file-ponyfill'
import cn from 'classnames'
import Camera, { FACING_MODES } from 'jslib-html5-camera-photo'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dataURItoBlob } from '../../utils/dataURItoBlob'
import Loader from '../Loader'
import SVG from '../Presentational/SVG'
import Title from '../Presentational/Title'
import styles from './camera.module.scss'



let camera: any = null

interface ICameraPhotoProps {
    title?: string
    onBack?(): void
    onBackTitle?: string
    wrapperClassName?: string
    onTakePhoto(file: File): void
    mobile?: boolean
}

const CameraPhoto = ({ title, onBack, onBackTitle, onTakePhoto, wrapperClassName, mobile }: ICameraPhotoProps) => {
    const [error, seterror] = useState('')
    const [ready, setready] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const { t } = useTranslation()

    useLayoutEffect(() => {
        if (videoRef.current) {
            camera = new Camera(videoRef.current)
            camera.startCamera(FACING_MODES.ENVIRONMENT)
                .then(() => setready(true))
                .catch((error: any) => {
                    camera = null
                    seterror(t('common:cameraError'))
                    alert(error.message)
                })
        }
        return () => {
            if (camera) camera.stopCamera()
            seterror('')
            setready(false)
        }
    }, [t])

    const takePhoto = useCallback(() => {
        let dataUri = camera.getDataUri({ sizeFactor: 1 })
        var blob = dataURItoBlob(dataUri)
        const img = new FilePonyfill([blob], `webcam_picture_${Date.now()}`, { type: 'image/png' })
        onTakePhoto(img)
    }, [onTakePhoto])

    return (
        <div className={styles.container}>
            {title && <div className={styles.header}>
                {onBack && <div className={styles.backButton}
                    onClick={() => onBack()}
                >
                    <SVG name='chevronLeft' />
                    {onBackTitle}
                </div>}
                <Title title={title} smallText noMargin />
            </div>}
            {!error && <div className={cn(styles.videoWrapper, wrapperClassName)}>
                <video ref={videoRef} autoPlay={true} playsInline className={cn({ [styles.mobile]: mobile })}>
                </video>
                {!ready && <Loader />}
            </div>
            }
            {(ready || error) && <div className={styles.footer}>
                {ready && <div className={styles.button}
                    onClick={takePhoto}>
                    <SVG name='camera' />
                </div>}
                {error && <div className={styles.error}>
                    {error}
                </div>}
            </div>}
        </div>)
}
export default CameraPhoto