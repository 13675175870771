import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSimplifyOnboardingCheck } from '../../../../../../utils/hooks'
import appRoutes from '../../../../../../utils/routes'
import { useRedirect } from '../../../../../../utils/useRedirect'
import ModalConfirm from '../../../../../Presentational/ModalConfirm'
import styles from "./style.module.scss"

const ls = "dashboard.deposit."

const useCompleteOnboardingModal = (type: 'deposit' | 'sidebar') => {
  const { t } = useTranslation()
  const history = useHistory()
  const { checkIfPendingReview, checkIfPendingDocs } = useSimplifyOnboardingCheck()
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const { continueSimplifiedFlow } = useRedirect()

  const handleCompleteOnboardingClick = useCallback(() => {
    setIsModalOpened(false)
    if (checkIfPendingDocs) {
      history.push(appRoutes.account.verify.id)
    } else if (!checkIfPendingReview) {
      continueSimplifiedFlow()
    }
  }, [checkIfPendingDocs, checkIfPendingReview, continueSimplifiedFlow, history])

  const modalContent = checkIfPendingReview
    ? t(`${ls}completeOnboardingNotice.pendingApproval`)
    : type === 'sidebar' ? t(`${ls}completeOnboardingNotice.sidebarModalContent`) : t(`${ls}completeOnboardingNotice.depositModalContent`)

  const completeButtonCaption = checkIfPendingReview ? t('common:ok') : t(`${ls}completeOnboardingNotice.completeButton`)

  const noticeModal = isModalOpened ? (
    <ModalConfirm
      showCross={false}
      isOpen={isModalOpened}
      title={t(`${ls}completeOnboardingNotice.title`)}
      onModalClose={() => setIsModalOpened(false)}
    >
      <div className={styles.noticeContent} dangerouslySetInnerHTML={{ __html: modalContent }} />
      <div className={styles.warningButtons}>
        <button
          className="link"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            setIsModalOpened(false)
          }}
        >
          {type === 'sidebar' ? t('common:back') : t('common:cancel')}
        </button>

        <button
          className="green"
          type="submit"
          onClick={handleCompleteOnboardingClick}
        >
          {completeButtonCaption}
        </button>
      </div>
    </ModalConfirm>
  ) : null

  return { noticeModal, setNoticeModalOpened: setIsModalOpened }
}

const useCompleteOnboardingNotice = () => {
  const { t } = useTranslation()
  const { continueSimplifiedFlow } = useRedirect()
  const { checkIfPendingReview } = useSimplifyOnboardingCheck()

  const notice = checkIfPendingReview ? (
    <div className={styles['complete-onboarding-section']}>
      {t(`${ls}completeOnboardingNotice.pendingApproval`)}
    </div>
  ) : (
    <div className={styles['complete-onboarding-section']}>
      {t(`${ls}completeOnboardingNotice.statusPage.tradingWithTempAccount`)}
      <span onClick={continueSimplifiedFlow} className={styles['complete-onboarding-button']}>
        {t(`${ls}completeOnboardingNotice.statusPage.completeProcess`)}
      </span>
      <span dangerouslySetInnerHTML={{ __html: t(`${ls}completeOnboardingNotice.statusPage.haveIDandAddress`) }} />
    </div>
  )

  return { completeOnboardingNotice: notice }
}

export { useCompleteOnboardingModal, useCompleteOnboardingNotice }

