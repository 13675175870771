import cn from 'classnames'
import { FC } from 'react'
import Image from '../Image'
import styles from './style.module.scss'

interface AppLogoProps {
  className?: string
}

const AppLogo: FC<AppLogoProps> = ({ className }) => {
  return (
    // <div className={cn(styles['app-logo'], className)}></div>
    <Image
      name={'tmLogo'}
      className={cn(styles['app-logo'], className)}
    />
  )
}


export default AppLogo
