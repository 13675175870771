import classNames from 'classnames/bind'
import React, { FC, memo, MouseEvent, VFC } from "react"
import { useTranslation } from 'react-i18next'
import SVG from '../../../Presentational/SVG'
import styles from './downloads.module.scss'

const cx = classNames.bind(styles)

const fireDownloadEvent = () => {
    window.dataLayer?.push({
        'event': 'custom_event',
        'event_name': 'download_app'
    })
}

interface DownloadData {
    image1: JSX.Element
    link1?: string
    image2: JSX.Element
    link2?: string
}

interface DownloadButtonsProps {
    download: DownloadData
}

export const DownloadButtons = memo((props: DownloadButtonsProps) => {
    const { download: { image1, image2, link1, link2 } } = props

    const handleClick = (e: MouseEvent) => {
        fireDownloadEvent()
        e.stopPropagation()
    }

    return (
        <div className={cx('buttons')}>
            <a href={link1} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                {image1}
            </a>
            <a href={link2} target="_blank" rel="noopener noreferrer" onClick={handleClick} className={styles.button2}>
                {image2}
            </a>
        </div>
    )
})

export const DownloadButton: FC<{
    image: JSX.Element
    link: string
}> = ({ image, link }) => {
    const handleClick = (e: MouseEvent) => {
        fireDownloadEvent()
        e.stopPropagation()
    }
    return (
        <div className={cx('button')}>
            <a href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                {image}
            </a>
        </div>
    )
}

export const MobileTitle = () => {
    const { t } = useTranslation()
    return <div className={cx('title')}>
        <SVG name='mobile' />
        <span>{t('common:mobile')}</span>
    </div>
}
export const DesktopTitle = () => {
    const { t } = useTranslation()
    return <div className={cx('title')}>
        <SVG name='desktop' />
        <span>{t('common:desktop')}</span>
    </div>
}
export const WebTitle = () => {
    const { t } = useTranslation()
    return <div className={cx('title')}>
        <SVG name='web' />
        <span>{t('common:web')}</span>
    </div>
}

export const Title = ({ text, icon }: { text: string, icon?: JSX.Element }) => {
    return <div className={cx('title')}>
        {icon}
        <span>{text}</span>
    </div>
}

export const SubTitle: VFC<{ text: string }> = ({ text }) => {
    return <div className={cx('sub-title')}>
        {text}
    </div>

}

export const QRCode: VFC<{
    image: string
    text?: boolean
    centered?: boolean
    classname?: string
}> = ({ image, classname, text = true, centered = false }) => {
    const { t } = useTranslation()
    return <div className={cx('qrCodeSection', { 'centered': centered })}>
        <div className={cx('qrCode', classname)}>
            <img src={image} alt="QR Code" />
            <div className={cx('bigQR')}>
                <img src={image} alt="QR Code" />
            </div>
        </div>
        {text && <span>{t('dashboard.downloads.scanToDownload')}</span>}
    </div>
}