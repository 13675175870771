import { useTranslation } from 'react-i18next'
import { Country } from '../../utils/types'
import Utils from '../../utils/utils'
import LoyaltyProgram from './LoayltyProgram'


interface IndividualTFSAProps {
    userCountry?: Country
}

export const IndividualTFSA = ({ userCountry }: IndividualTFSAProps) => {
    const { t } = useTranslation()
    const prefix = 'footer:individual.TFSA'
    const isSouthAfricaCountry = Utils.checkIfCountryIsSA(userCountry)

    return (
        <>
            <LoyaltyProgram prefix={prefix} />
            <p>
                <strong>{t(`${prefix}.warning.header`)} </strong>
                {t(`${prefix}.warning.text`)}
            </p>
            {isSouthAfricaCountry && <p>{t(`${prefix}.warning.text1`)}</p>}
            <p>
                <strong>{t(`${prefix}.company.header`)} </strong>
                {t(`${prefix}.company.text`)}
            </p>
            <p> {t(`${prefix}.text`)}</p>
        </>
    )
}
