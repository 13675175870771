import { appConfig } from "../config";
import { Loyalty } from "../redux/appState";

export const saleforceLoyaltyRequest = async (userProfileId?: number): Promise<Loyalty> => {
    const response = await fetch(`${appConfig.LOYALTY_SF_API_URL}/api/client/status/${userProfileId}`, {
        method: "GET"
    }).then((response) => {
        return response.json()
    }).catch((error) => {
        console.log(error);
    })
    return response
}