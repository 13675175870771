import { useTranslation } from "react-i18next"
import { useDangerousHTML, useIsLoyaltyProgramScreen } from "../../utils/hooks"
import styles from "./style.module.scss"

const LoyaltyProgram = ({ prefix }: { prefix: string }) => {
    const { t } = useTranslation()
    const { s } = useDangerousHTML()
    const isLoyaltyProgramScreen = useIsLoyaltyProgramScreen()

    return isLoyaltyProgramScreen ? <p> <strong>{t(`${prefix}.termsAndConditions.header`)} </strong>
        {s(t(`${prefix}.termsAndConditions.text`), styles.a)}</p > : null


}

export default LoyaltyProgram