import { useTranslation } from 'react-i18next'
import { useDangerousHTML } from '../../utils/hooks'
import LoyaltyProgram from './LoayltyProgram'

export const IndividualTMBM = () => {
    const { t } = useTranslation()
    const { p } = useDangerousHTML()
    const prefix = 'footer:individual.TMBM'

    return <>
        <LoyaltyProgram prefix={prefix} />
        <p>
            <strong>{t(`${prefix}.warning.header`)} </strong>
            {t(`${prefix}.warning.text1`)}&nbsp;
            {/* <HttpLink url={t(`${prefix}.warning.links.productDisclosure.link`)}>
                {t(`${prefix}.warning.links.productDisclosure.text`)}
            </HttpLink>
            &nbsp;{t(`${prefix}.warning.text2`)}&nbsp;
            <HttpLink url={t(`${prefix}.warning.links.financialServicesGuide.link`)}>
                {t(`${prefix}.warning.links.financialServicesGuide.text`)}
            </HttpLink>
            &nbsp;{t(`${prefix}.warning.text3`)} */}
        </p>
        {/*         <p>
            <strong>{t(`${prefix}.company1.header`)} </strong>
            {t(`${prefix}.company1.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.company2.header`)} </strong>
            {t(`${prefix}.company2.text`)}
        </p>
 */}         <p>
            <strong>{t(`${prefix}.company3.header`)} </strong>
            {t(`${prefix}.company3.text`)}
        </p>
        {/*         {p(t(`${prefix}.company5`))}
        <p>
            <strong>{t(`${prefix}.company4.header`)} </strong>
            {t(`${prefix}.company4.text`)}
        </p>
 */}        <p> {t(`${prefix}.text`)}</p>
    </>
}
