import { createAction } from "@reduxjs/toolkit"
import { AccountStat, AppSubmitedParams, Country, LoginActionParams } from "../utils/types"

export const login = createAction<LoginActionParams>("login")
export const logout = createAction("logout")
export const getAccounts = createAction("getAccounts")
export const getOrganizationSettings = createAction<Country | undefined>("getOrganizationSettings")
export const getNotifications = createAction<boolean | undefined>("getNotifications")
export const getNotificationsMap = createAction("getNotificationsMap")
export const getBankAccounts = createAction("getBankAccounts")
export const getUser = createAction("getUser")
export const getAppStatus = createAction<number>("getAppStatus")
export const getLoyalityStatus = createAction<number>("getLoyalityStatus")
export const sendLastField = createAction<any>("sendLastField")
export const reloadAppInfo = createAction("reloadAppInfo")
export const startPullAppStatus = createAction("startPullAppStatus")
export const stopPullAppStatus = createAction("stopPullAppStatus")
export const startCheckActivity = createAction("startCheckActivity")
export const stopCheckActivity = createAction("stopCheckActivity")
export const startRefreshAccounts = createAction("startRefreshAccounts")
export const stopRefreshAccounts = createAction("stopRefreshAccounts")
export const getUserApps = createAction("getUserApps")
export const appFailed = createAction("appFailed")
export const getQuestions = createAction("getQuestions")
export const appSubmited = createAction<Partial<AppSubmitedParams>>("appSubmited")
export const corporateAppSubmited = createAction<Partial<AppSubmitedParams>>("corporateAppSubmited")
export const getMigrationStatus = createAction("getMigrationStatus")
export const checkUserTermsAndConditions = createAction("checkUserTermsAndConditions")
export const checkDemoExpiration = createAction("checkDemoExpiration")
export const checkSubscriptionStatus = createAction("checkSubscriptionStatus")
export const checkUserReferrerStatus = createAction<number>("checkUserReferrerStatus")
export const fa2checked = createAction("fa2checked")
export const logoutTerminatedUserIfNeeded = createAction<AccountStat[]>("logoutTerminatedUserIfNeeded")
export const launchAppPreload = createAction("launchAppPreload")
