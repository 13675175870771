import React, { ReactNode } from "react"
import cn from "classnames"

import styles from "./title.module.scss"

type TitleProps = {
    title: ReactNode
    className?: string
    noMargin?: boolean
    huge?: boolean
    smallText?: boolean
    mediumText?: boolean
}
const Title = (props: TitleProps) => (
    <div className={cn(styles.title,
        {
            [styles['no-margin']]: props.noMargin,
            [styles['huge']]: props.huge,
            [styles['smallText']]: props.smallText,
            [styles['mediumText']]: props.mediumText
        },
        props.className)}>{props.title}</div>
)

export default Title