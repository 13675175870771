
export type DocSide = 'FRONTSIDE' | 'BACKSIDE' | 'GENERAL'
export type JorneyStep = 'start' | 'selectFileOrCamera' | 'takePhoto' | 'previewFile' | 'previewPhoto' | 'tryAgain' | 'fail' | 'passed'
export type UploadData = {
    PersonEntryId: string
    AdditionalData:
    {
        Name: string
        Value: string | number
    }[]
    metadata:
    {
        Name: string
        Value: string
        step: DocSide
        type: 'Simple'
    }[]
    ExcludeOutputImagesFromResponse: boolean
    InputImages:
    {
        Data: string
        ImageFormat: string
        Name: string
        inputFormat: number
    }[]
    IsDocumentExtracted: boolean
    Source: number
}

export type RequiredAction =
    'NONE' |
    'BACKSIDE' |
    'FRONTSIDE:SECONDSCAN' |
    'FRONTSIDE:THIRDSCAN' |
    'BACKSIDE:SECONDSCAN' |
    'BACKSIDE:THIRDSCAN'

export type CurrentResult = string | 'Pass' | 'Failed' | 'ProcessingError' | 'NetworkError'

export interface UploadResponse {
    PersonEntryId: string
    EntryDateTime: Date
    RequiredAction: RequiredAction
    CurrentResult: CurrentResult
    HighLevelResult: string
    EntryImages: any[]
    IsFinished: boolean
    RequestId: string
    HasError: boolean
    ResultDetails: string[]
    EntryData?: EntryData
}

export interface EntryData {
    Platform: string
    JourneyDefinitionId: string
    UserName: string
    RequiredJourneySteps: string
    journeyDefinitionName: string
    OverallAuthenticationState: string
    UnderAgeRuleResult: string
    EnhancedTamper: string
    DocumentType: string
    CountryCode: string
    ScanReason: string
    AuthenticationLevel: string
    ContainsRfid: boolean
    ScanReference: string
}

export type StatusAfterKYC = 'PENDING_KYC' | 'APPROVED' | 'PENDING_REVIEW'

export const MAX_FILE_SIZE = 7.5 * 1024 * 1024
export const acceptedFormats = ['image/jpeg', 'application/pdf', 'image/png']
