import { withTransaction } from '@elastic/apm-rum-react'
import cn from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Store } from '../../../../redux/store'
import { useScreen } from '../../../../utils/hooks'
import Routes from '../../../../utils/routes'
import { scrollRef } from "../../../../utils/scroll"
import { useThemes } from '../../../../utils/themes'
import Footer from '../../../Footer'
import Image from '../../../Presentational/Image'
import { CompactLangSelect } from '../../../Presentational/LangSelect'
import useLiveChat from '../../../Presentational/LiveChat'
import styles from './style.module.scss'

interface BaseViewProps {
    className?: string
    secondCard?: JSX.Element
    customFooter?: JSX.Element
    stickWarning?: boolean
}

const BaseView: FC<PropsWithChildren<BaseViewProps>> = ({
    className,
    children,
    secondCard,
    stickWarning = false,
    customFooter }) => {
    const { language } = useSelector((state: Store) => state.App)
    const { floatLiveChat, fixedLiveChat } = useLiveChat(true)
    const { isMobile } = useScreen()
    const { account, register } = Routes
    const { theme } = useThemes()
    const showLangSelector = language === 'ja'
        ? false
        : [
            account.createAccount.live,
            account.createAccount.demo,
            register.live,
            register.ib,
            account.login,
        ].includes(window.location.pathname)
    const logoImage = theme === 'dark' ? 'tmLogo' : 'tmLogoLight'
    return (
        <div className={styles.baseView} ref={scrollRef} id="base">
            <div className={styles.content}>
                <div className={styles.header}>
                    <Image name={logoImage} className={styles.logo} />
                    {showLangSelector && <CompactLangSelect />}
                </div>
                <div className={cn("fullscreen-card", styles.mainContent,
                    { [styles.noLangSelector]: !showLangSelector },
                    className)}>
                    {children}
                    {isMobile
                        ? fixedLiveChat
                        : <div className={styles.chat}>
                            {floatLiveChat}
                        </div>}
                </div>
                {secondCard &&
                    <div className={cn("fullscreen-card", styles.mainContent)}>
                        {secondCard}
                    </div>}
            </div>
            <Footer
                customFooter={customFooter}
                isExtended
                stickWarning={stickWarning}
            />
        </div>
    )

}

export default withTransaction('BaseView', 'component')(BaseView)



