import { PortalAccountDomain } from "../../../../../utils/enums"
import Utils from "../../../../../utils/utils"

export const CHECKOUT_COM_PUBLIC_KEY_TEST = 'pk_sbox_dl635mbmf2ee4u66d2fuyk5koei'
export const CHECKOUT_COM_PUBLIC_KEY_PROD = 'pk_5y5f2iimejazlzqp7lblajsshu6'
export const TMBM_CHECKOUT_COM_PUBLIC_KEY_PROD = 'pk_pxagxd6bjudj6qkl4szq6ivh7qk'

export const getCheckoutComPulicKey = (portalAccountDomain: PortalAccountDomain) => {
  if (Utils.isTestEnv()) return CHECKOUT_COM_PUBLIC_KEY_TEST

  switch (portalAccountDomain) {
    case PortalAccountDomain.TMBM:
    case PortalAccountDomain.TMCY:
    case PortalAccountDomain.TMLC:
    case PortalAccountDomain.TMSY:
      return TMBM_CHECKOUT_COM_PUBLIC_KEY_PROD
    default:
      return CHECKOUT_COM_PUBLIC_KEY_PROD
  }
}

const getMerchantID = (portalAccountDomain: PortalAccountDomain) => {
  switch (portalAccountDomain) {
    case PortalAccountDomain.TMNZ:
      return Utils.isTestEnv() ? 'merchant.apple-pay-test' : 'merchant.apple-pay-prod'
    default:
      return Utils.isTestEnv() ? 'merchant.apple-pay-test' : 'merchant.apple-pay-eu-prod'
  }
}

export const checkIfApplePayAvailable = async (portalAccountDomain: PortalAccountDomain): Promise<boolean> => {
  if (window.ApplePaySession) {
    const merchantID = getMerchantID(portalAccountDomain)
    const canMakePaymentsWithActiveCard = await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantID)
    return canMakePaymentsWithActiveCard
  }
  return false
}
