import { TFunction } from 'i18next'

import { AccountProcessor, Country } from '../../../../../utils/types'

export const unionPayNames = ['FastPay p2p', 'Zotapay-CUP p2p', 'AloPay', 'RPN p2p', 'Nova2pay', 'Nova2Pay p2p', 'Transact365 p2c']
export const processorsWithNotice = [...unionPayNames, 'MPSA', 'OTC365']

export const checkForUnionPay = (processor?: AccountProcessor) => {
  return processorsWithNotice.includes(processor?.name || '')
}

export const checkForCCprocessor = (processor?: AccountProcessor) => {
  return ['Credit Card', 'Credit Card (SC)'].includes(processor?.name || '')
}

export const getProcessorName = (option: AccountProcessor, t: TFunction) =>
  t(`processors:${option.displayName || option.name}:title`, option.displayName)

const DEFAULT_SORT_NUMBER = 100

const CHN_SORT: Record<string, number> = {
  "MyPay": 2,
  "OTC365": 1,
  "Zotapay-CUP p2p": 3,
}

export const sortProcessors = (processors: AccountProcessor[], userCountry?: Country) => {
  if (userCountry?.code3 === 'CHN') {
    return [...processors].sort((a, b) =>
      (CHN_SORT[a.name] || DEFAULT_SORT_NUMBER) - (CHN_SORT[b.name] || DEFAULT_SORT_NUMBER))
  }
  return [...processors]
}

export const submitPostForm = (method: string, url: string, params: any, acceptCharset?: string, target?: string) => {
  var form = document.createElement("form")
  form.action = url
  form.method = method
  form.target = target || "_self"
  form.acceptCharset = acceptCharset || "UTF-8"
  if (params) {
    for (var key in params) {
      var input = document.createElement("textarea")
      input.name = key
      input.value = typeof params[key] === "object"
        ? JSON.stringify(params[key])
        : params[key]
      form.appendChild(input)
    }
  }
  form.style.display = 'none'
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export const ibwProcessor: AccountProcessor = {
  id: -111,
  autoWithdrawalAllowed: false,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 0,
  displayName: 'International Bank Transfer',
  isClientDepositAllowed: false,
  isClientWithdrawalAllowed: false,
  name: 'International Bank Transfer',
  processorFee: 0,
  refundAllowed: false,
  type: "Bank",
  used: true,
}

export const cryptoProcessor: AccountProcessor = {
  id: -222,
  autoWithdrawalAllowed: false,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 0,
  displayName: 'Crypto',
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  name: 'Crypto',
  processorFee: 0,
  refundAllowed: false,
  type: "e-Wallet",
  used: true,
}

export const googlePayProcessor: AccountProcessor = {
  id: -444,
  autoWithdrawalAllowed: true,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 1000,
  displayName: "Google Pay",
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  limit: undefined,
  name: "Google Pay",
  processorFee: 1,
  refundAllowed: false,
  type: "CC",
  used: true,
}

export const applePayProcessor: AccountProcessor = {
  id: -555,
  autoWithdrawalAllowed: true,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 1000,
  displayName: "Apple Pay",
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  limit: undefined,
  name: "Apple Pay",
  processorFee: 1,
  refundAllowed: false,
  type: "CC",
  used: true,
}
